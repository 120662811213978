import { Button, Typography, Divider, Table } from 'antd'
import React from 'react'
import { useBreadcrumb } from '~/hooks/breadcrumbs'
import { Flex } from '~/components'
import { useRequest } from '~/hooks/use-request'
import { LoadingOutlined } from '@ant-design/icons'

import dayjs from 'dayjs'
import { config } from '~/config'

export const CourseraLicenses = () => {
  useBreadcrumb(3, { label: 'Coursera Licenses', path: '/partners/coursera' })

  return (
    <Flex column style={{ flex: 1 }}>
      <Typography.Title style={{ marginBottom: '24px' }}>Coursera License Management</Typography.Title>
      <Button href={config.courseraConfig.adminPanelUrl} target='_blank'>
        Go to Coursera Admin Dashboard
      </Button>
      <Divider />
      <LicenseStats />
      <Typography.Title>Alocated Licenses</Typography.Title>
      <TableLicenses />
      <Divider />
    </Flex>
  )
}

const LicenseStats = () => {
  const { data, error, isLoading, mutate } = useRequest(
    '/course-providers/coursera/license-stats',
    undefined,
    {
      swrOptions: {
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        refreshInterval: 5000,
      },
    }
  )

  if (isLoading) {
    return <LoadingOutlined />
  }

  if (error) {
    return <Button onClick={mutate}>Retry</Button>
  }

  return (
    <Flex column>
      <Flex column>
        <Typography style={{ fontSize: 24 }}>Total Purchased Licenses: {data.total}</Typography>
        <Typography>
          Last updated: {dayjs(data.lastUpdatedAt).format(config.DATE_FORMAT_FULL)}
        </Typography>
      </Flex>
      <Divider />
      <Typography>In Use: {data.inUse}</Typography>
      <Typography>Available: {data.available}</Typography>
      <Typography>Waiting: {data.waiting}</Typography>
      <Typography>Should order more licenses: {data.shouldOrderMoreLicenses ? 'Yes' : 'No'}</Typography>
      <Divider />
    </Flex>
  )
}

const TableLicenses = () => {
  const { data, error, isLoading, mutate } = useRequest(
    '/course-providers/coursera/list-licenses',
    undefined,
    {
      swrOptions: {
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        refreshInterval: 5000,
      },
    }
  )

  const dataSource = data?.licenses?.map((license, index) => ({
    key: index,
    userEmail: license.userEmail,
    isWaiting: license.isWaiting ? 'Yes' : 'No',
    assignmentDate: dayjs(license.assignmentDate).format(config.DATE_FORMAT_FULL),
    courseNames: license.courseNames.join(' | '),
  }))

  const columns = [
    {
      title: 'User Email',
      dataIndex: 'userEmail',
      key: 'userEmail',
    },
    {
      title: 'Is Waiting',
      dataIndex: 'isWaiting',
      key: 'isWaiting',
    },
    {
      title: 'Assignment Date',
      dataIndex: 'assignmentDate',
      key: 'assignmentDate',
    },
    {
      title: 'Course Names',
      dataIndex: 'courseNames',
      key: 'courseNames',
    },
  ]

  if (isLoading) {
    return <LoadingOutlined />
  }

  if (error) {
    return <Button onClick={mutate}>Retry</Button>
  }

  return (
    <Flex style={{ width: '100%' }}>
      <Table style={{ width: '100%' }} dataSource={dataSource} columns={columns} />
    </Flex>
  )
}
