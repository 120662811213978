import styled, { css } from 'styled-components'

export const Container = styled.div`
  padding: ${props => (props.small ? 20 : 35)}px calc((100vw - 1340px) / 2);

  @media (max-width: 1380px) {
    padding: ${props => (props.small ? 20 : 35)}px 20px;
  }

  @media (max-width: 720px) {
    padding: ${props => (props.small ? 15 : 35)}px 10px;
  }

  ${props =>
    props.full &&
    css`
      padding-left: 0 !important;
      padding-right: 0 !important;
    `}

  ${props =>
    props.noPadding &&
    css`
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    `};
`
