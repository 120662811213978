import React from 'react'
import styled from 'styled-components'
import { Flex, Text } from '~/components'

const StyledFlex = styled(Flex)`
  width: ${props => props.size ?? '150'}px;
  height: ${props => props.size ?? '150'}px;
  border-radius: 50%;
  border: solid 2px ${props => props.color};
  ${props => (props.fill ? `background: ${props.color}33;` : '')}
  text-align: center;
  padding: 5px;
`

export const Circle = ({ children, fill = false, ...props }) => (
  <StyledFlex fill={fill.toString()} justifyCenter alignCenter {...props}>
    {children}
  </StyledFlex>
)

export const StatCircle = ({ header, value, headerSize = 15, valueSize = 25, ...props }) => (
  <Circle fill column {...props} style={{ margin: '10px' }}>
    <Text center medium size={headerSize} style={{ marginBottom: '5px' }}>
      {header}
    </Text>
    <Text center bold size={valueSize}>
      {value}
    </Text>
  </Circle>
)
