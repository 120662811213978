import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { Button, Form, Modal, Typography } from 'antd'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import * as yup from 'yup'
import styled from 'styled-components'
import { Flex } from '~/components'
import { useLoading } from '~/hooks/use-loading'
import { UploadImage } from '~/components/UploadImage'
import { request } from '~/http'
import { uploadFile } from '~/http/uploadFile'

const schema = yup.object().shape({
  image: yup
    .mixed()
    .test('required', 'Image is required', value => !!value)
    .test('fileSize', 'The image is too large', value => {
      if (!value.size) return true

      return value.size <= 10 * 1024 * 1024
    })
    .test('fileFormat', 'The upload image must be of either jpg or png format', value => {
      if (!value.size) return true

      return ['image/jpeg', 'image/png', 'image/svg+xml', 'image/gif', 'image/webp'].includes(value.type)
    }),
})

const UploadImageStyled = styled(UploadImage)`
  width: 300px;
  height: 300px;

  .ant-upload {
    width: 100%;
    height: 100%;
  }
`

export const ProfileImageUploadModal = ({ visible, user, hide, onSuccess }) => {
  const { isLoading, enableLoading } = useLoading()
  const [key, setKey] = useState(0)

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
    reset,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: { image: '' },
  })

  const hideAndClearModal = () => {
    // this is needed to force a re-mount of the
    // <UploadImageStyled /> component which clears it
    setKey(prev => prev + 1)
    reset()
    hide()
  }

  const onSubmit = enableLoading(async ({ image }) => {
    try {
      const { location } = await uploadFile(image)

      await request('/user/set-profile-image', {
        userId: user.id,
        sourceLocation: location,
      })

      onSuccess?.()

      toast.success(`Successfully updated profile image for ${user.username}`)
    } catch (e) {
      toast.error(`Your upload failed. Please try again. Reason: ${e.message}`)
    }

    hideAndClearModal()
  })

  return (
    <Modal visible={visible} title='Upload Profile Image' footer={null} onCancel={hideAndClearModal}>
      <Form size='large' layout='vertical' autoComplete='off' onFinish={handleSubmit(onSubmit)}>
        <Typography className='Text' style={{ marginBottom: '24px', fontSize: '18px' }}>
          Choose an image to be uploaded:
        </Typography>

        <Form.Item
          name='image'
          validateStatus={errors.image?.message && 'error'}
          help={errors.image?.message}
          style={{ marginBottom: '48px' }}>
          <Controller
            control={control}
            name='image'
            render={({ field: { value, ...otherField } }) => (
              <Flex justifyCenter>
                <UploadImageStyled key={key} {...otherField} />
              </Flex>
            )}
          />
        </Form.Item>

        <Form.Item noStyle>
          <Flex justifyEnd>
            <Button
              type='default'
              htmlType='button'
              onClick={hideAndClearModal}
              style={{ marginRight: '16px' }}>
              Cancel
            </Button>

            <Button type='primary' htmlType='submit' loading={isLoading} disabled={!isValid}>
              Upload
            </Button>
          </Flex>
        </Form.Item>
      </Form>
    </Modal>
  )
}
