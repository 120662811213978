import React from 'react'
import { Modal, Typography, Button } from 'antd'
import { useLoading } from '~/hooks/use-loading'
import { toast } from 'react-toastify'
import { request } from '~/http'
import { Flex } from '~/components'
import { useParams } from 'react-router'

export const RemoveUsersFromClientModal = ({
  open,
  setOpen,
  usersToRemove,
  setSelectedRowKeys,
  refetchDetails,
}) => {
  const { isLoading, enableLoading } = useLoading()
  const { company } = useParams()

  const handleCancel = () => {
    setSelectedRowKeys([])
    setOpen(false)
  }

  const handleDeleteUsers = enableLoading(async () => {
    try {
      await request('/clients/remove-users', {
        company,
        emails: [...usersToRemove],
      })

      await refetchDetails()
      handleCancel()
    } catch (e) {
      toast.error(`Error removing users: ${e.message || e}`)
    }
  })

  return (
    <Modal
      open={open}
      onCancel={handleCancel}
      onOk={handleDeleteUsers}
      footer={[
        <Button key='cancel' onClick={handleCancel}>
          Cancel
        </Button>,
        <Button key='add' disabled={isLoading || usersToRemove.lenght === 0} onClick={handleDeleteUsers}>
          Remove Users
        </Button>,
      ]}>
      <Typography.Title>
        Remove {usersToRemove.length} Users from {company}
      </Typography.Title>

      <Flex flexDirection='row' gap='24px'>
        <Typography.Text>
          Users will lose access to your Audo Program. You can invite them to rejoin your program any
          time.
        </Typography.Text>
      </Flex>
    </Modal>
  )
}
