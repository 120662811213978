import React from 'react'

import { useLocation } from 'react-router'

import { Menu, Dropdown } from 'antd'

import {
  MenuOutlined,
  UserOutlined,
  SearchOutlined,
  ConsoleSqlOutlined,
  QuestionOutlined,
  TeamOutlined,
  FileTextOutlined,
  SolutionOutlined,
} from '@ant-design/icons'
import { Link } from 'react-router-dom'

export const hamburgerMenuItems = [
  {
    label: 'Users',
    path: '/users',
    icon: <UserOutlined />,
  },
  {
    label: 'B2B Clients',
    path: '/b2b-clients',
    icon: <SolutionOutlined />,
  },
  {
    label: 'SEO',
    path: '/seo',
    icon: <SearchOutlined />,
  },
  {
    label: 'Gamification',
    path: '/gamification',
    icon: <ConsoleSqlOutlined />,
  },
  {
    label: 'FAQ',
    path: '/faq',
    icon: <QuestionOutlined />,
  },
  {
    label: 'Blog',
    path: '/blog',
    icon: <FileTextOutlined />,
  },
  {
    label: 'Admininistrators',
    path: '/admins',
    icon: <TeamOutlined />,
  },
  {
    label: 'Partners',
    path: '/partners',
    icon: <TeamOutlined />,
    type: 'divider',
  },
]

export const Hamburger = () => {
  const location = useLocation()
  return (
    <Dropdown
      overlay={
        <Menu selectedKeys={`/${location.pathname.split('/')[1]}`}>
          {hamburgerMenuItems.map(item => {
            return (
              <Menu.Item key={item.path} icon={item.icon}>
                <Link to={item.path}>{item.label}</Link>
              </Menu.Item>
            )
          })}
        </Menu>
      }
      trigger={['click']}>
      <Link
        to={''}
        className='ant-dropdown-link'
        onClick={e => e.preventDefault()}
        data-testid='hamburger-menu'>
        <MenuOutlined style={{ fontSize: '20px', marginTop: '10px' }} />
      </Link>
    </Dropdown>
  )
}
