import { useContext, useEffect, useState } from 'react'
import { BreadcrumbsContext } from '~/contexts/breadcrumbs'

export const useProvideBreadcrumbs = ({ defaultState = {} } = {}) => {
  const [breadcrumbsState, setBreadcrumbsState] = useState(defaultState)

  const setBreadcrumb = (level, { label, path }) => {
    if (level === undefined || !label || !path) {
      throw new Error('Please pass breadcrumbs as useBreadcrumb(level, { label, path })')
    }

    setBreadcrumbsState(prev => ({
      ...prev,
      [level.toString()]: { label, path },
    }))
  }

  const clearBreadcrumb = level => {
    setBreadcrumbsState(prev => {
      return { ...prev, [level.toString()]: undefined }
    })
  }

  const breadcrumbs = Object.keys(breadcrumbsState)
    .sort((a, b) => parseInt(a) - parseInt(b))
    .map(key => breadcrumbsState[key])
    .filter(Boolean)

  return {
    breadcrumbs,
    setBreadcrumb,
    clearBreadcrumb,
  }
}

export const useBreadcrumbs = () => {
  const state = useContext(BreadcrumbsContext)

  return state.breadcrumbs
}

export const useBreadcrumb = (level, { label, path }) => {
  const { setBreadcrumb, clearBreadcrumb } = useContext(BreadcrumbsContext)

  useEffect(() => {
    setBreadcrumb(level, { label, path })
    return () => {
      clearBreadcrumb(level)
    }
  }, [level, label, path])
}
