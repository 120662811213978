import React, { useState } from 'react'
import { Modal, Typography, Input, Button, DatePicker } from 'antd'
import { useLoading } from '~/hooks/use-loading'
import { toast } from 'react-toastify'
import { request } from '~/http'

export const AddFreeAccessUserModal = ({ open, setOpen, freeAccessUserList }) => {
  const { isLoading, enableLoading } = useLoading()
  const [input, setInput] = useState('')
  const [expirationDate, setExpirationDate] = useState(null)

  const handleAddFreeUser = enableLoading(async () => {
    try {
      const emails = input
        .trim()
        .split(/\r?\n/)
        .filter(email => email.length > 0)

      if (emails.length > 0) {
        const result = await request('/admin/free-access/add-users', {
          emails: [...emails],
          expirationDate: expirationDate?.format('YYYY-MM-DD'),
        })

        if (result.errorUserList.length > 0) {
          result.errorUserList.forEach(error => {
            toast.error(`Error granting access. ${error.email}: ${error.message}`)
          })
        } else {
          await freeAccessUserList()
          toast.success('Successfully granted users free access')
        }
        setInput('')
        setOpen(false)
        return
      }

      toast.error('No users were granted free access')
    } catch (e) {
      toast.error(`Error granting users free access: ${e.message || e}`)
    }
  })

  const handleCancel = () => {
    setInput('')
    setOpen(false)
  }

  return (
    <Modal
      open={open}
      onCancel={handleCancel}
      onOk={handleAddFreeUser}
      footer={[
        <Button key='cancel' onClick={handleCancel}>
          Cancel
        </Button>,
        <Button key='add' onClick={handleAddFreeUser} disabled={input === '' || isLoading}>
          Add Users
        </Button>,
      ]}>
      <Typography.Title>Grant User Free Access</Typography.Title>
      <Typography.Text>
        Enter the emails of the users you wish to grant free access. One email for each line.
      </Typography.Text>
      <Input.TextArea
        autoSize={{ minRows: 6, maxRows: 6 }}
        onChange={e => setInput(e.target.value)}
        value={input}
        disabled={isLoading}
        style={{ marginTop: '24px' }}
      />

      <Typography.Title style={{ marginTop: '25px' }}>Expiration Date</Typography.Title>
      <Typography.Text>
        These users will lose free access to Audo+ on the date you specify below.
      </Typography.Text>
      <br />
      <DatePicker
        dateRender={current => {
          return <div className='ant-picker-cell-inner'>{current.date()}</div>
        }}
        onChange={date => setExpirationDate(date)}
        style={{ marginTop: '24px' }}
      />
    </Modal>
  )
}
