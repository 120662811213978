import { Typography, Space, Divider } from 'antd'
import React from 'react'
import { useRequest } from '~/hooks/use-request'
import { useBreadcrumb } from '~/hooks/breadcrumbs'
import { useUser } from '~/hooks/auth'
import { Flex, Text, StatCircle, Loader } from '~/components'
import { permissions } from '~/config/permissions'

const Overview = ({ userCount, loggedInUsers }) => (
  <>
    <StatCircle
      header='Total Users'
      headerSize={20}
      value={userCount}
      valueSize={30}
      fill
      color='#1d9927'
      size='200'
      style={{ marginBottom: '10px' }}
    />
    <Flex>
      <Space size='large'>
        <Text inline>Logged in users</Text>
        <Text inline>
          {loggedInUsers.active ?? 'TBD'}
          {' Active | '}
          {loggedInUsers.inactive ?? 'TBD'} Inactive
        </Text>
      </Space>
    </Flex>
  </>
)

const NewUsers = ({ newUsers }) => {
  const NewUserCircle = ({ ...props }) => <StatCircle {...props} color='#3b92d4' />

  return (
    <>
      <Typography.Title level={2}>New Users</Typography.Title>
      <Flex>
        <NewUserCircle header='Today' value={newUsers.today} />
        <NewUserCircle header='This Week' value={newUsers.thisWeek} />
        <NewUserCircle header='This Month' value={newUsers.thisMonth} />
        <NewUserCircle header='This Quarter' value={newUsers.thisQuarter} />
        <NewUserCircle header='This Year' value={newUsers.thisYear} />
      </Flex>
    </>
  )
}

const CourseStats = ({ courseStats }) => {
  const CourseStatCircle = ({ ...props }) => <StatCircle {...props} color='#993bd4' />

  return (
    <>
      <Typography.Title level={2}>Course Stats</Typography.Title>
      <Flex>
        <CourseStatCircle header='Courses In Progress' value={courseStats.coursesInProgress ?? 'TBD'} />
        <CourseStatCircle header='Courses Completed' value={courseStats.coursesCompleted ?? 'TBD'} />
      </Flex>
    </>
  )
}

export const Dashboard = () => {
  useBreadcrumb(2, { label: 'Dashboard', path: '/users/dashboard' })
  const { data, error, isLoading } = useRequest('/users/get-stats', undefined, {
    swrOptions: {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      refreshInterval: 5000,
    },
  })

  const { user } = useUser()
  const isAllowed = user.isPermitted(permissions.user_read) || user.isPermitted(permissions.user_write)

  return (
    <Flex column alignCenter style={{ width: '100%' }}>
      <Typography.Title>Dashboard</Typography.Title>
      {isLoading && <Loader placeholder />}
      {isAllowed && data && (
        <Flex column alignCenter style={{ width: '100%' }} data-testid='users-stats'>
          <Overview userCount={data.userCount} loggedInUsers={data.loggedInUsers} />
          <Divider />
          <NewUsers newUsers={data.newUsers} />
          <Divider />
          <CourseStats courseStats={data.courseStats} />
        </Flex>
      )}
      {!isAllowed && (
        <Typography.Text>You do not have permission to view the Users Dashboard.</Typography.Text>
      )}
      {isAllowed && error && <Typography.Text>{error.message}</Typography.Text>}
    </Flex>
  )
}
