import React from 'react'
import { Table, Tag, Typography } from 'antd'
import dayjs from 'dayjs'
import { Link } from 'react-router-dom'
import { config } from '~/config'
import { useUser } from '~/hooks/auth'
import { userStatusConfigs } from '~/utils/user'

const getColumns = () => [
  {
    title: 'Date Added',
    dataIndex: 'dateAdded',
    key: 'dateAdded',
    render: dateAdded => (dateAdded ? dayjs(dateAdded).format(config.DATA_FORMAT_DATE_ONLY) : null),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: status => userStatusConfigs[status || 'enrolled'].label,
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Tag',
    dataIndex: 'eventType',
    key: 'eventType',
  },
  {
    title: 'Meta Data',
    dataIndex: 'metadata',
    key: 'metadata',
    render: metaData => {
      const sortedMetaData = metaData.sort()

      return (
        <span>
          {sortedMetaData.map(metaData => (
            <Tag
              style={{ margin: '4px' }}
              data-testid={`metadata-${metaData}`}
              color='geekblue'
              key={metaData}>
              {metaData.toUpperCase()}
            </Tag>
          ))}
        </span>
      )
    },
  },
  {
    title: 'Event Description',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'Module',
    dataIndex: 'modules',
    key: 'modules',
    render: modules => (
      <span>
        {modules.map(module => (
          <Typography.Text key={module}>{module}</Typography.Text>
        ))}
      </span>
    ),
  },
  {
    title: '',
    dataIndex: 'eventType',
    key: 'details',
    render: eventType => <Link to={`/gamification/event-details/${eventType}`}>View Details</Link>,
  },
]

export const EventsTable = ({ events, loading, handleChange }) => {
  const { user } = useUser()

  return user.isEmpty() ? null : (
    <Table
      data-testid='events-table'
      loading={loading}
      style={{ width: '100%' }}
      dataSource={events}
      pagination={false}
      sortDirections={['ascend', 'descend']}
      // onChange={(pagination, filters, sort) => {
      //   handleChange(pagination.current, filters, sort)
      // }}
      columns={getColumns()}
    />
  )
}
