import { Auth0Provider } from '@auth0/auth0-react'
import React, { useEffect, useState } from 'react'
import { config } from '~/config'
import { BreadcrumbsContext } from '~/contexts/breadcrumbs'
import { useProvideBreadcrumbs } from '~/hooks/breadcrumbs'

export const Providers = ({ children }) => {
  const breadcrumbs = useProvideBreadcrumbs()
  const [redirect, setRedirect] = useState()

  useEffect(() => {
    if (redirect) window.location.href = redirect
  }, [redirect])

  return (
    <Auth0Provider
      domain={config.auth.domain}
      clientId={config.auth.clientId}
      redirectUri={window.location.origin}
      audience={config.auth.audience}
      scope='read:current_user update:current_user_metadata'
      onRedirectCallback={state => {
        if (state?.redirectUrl) {
          setRedirect(redirect)
        }
      }}>
      <BreadcrumbsContext.Provider value={breadcrumbs}>{children}</BreadcrumbsContext.Provider>
    </Auth0Provider>
  )
}
