import React, { useState } from 'react'
import { Table, Button, Input } from 'antd'
import { LoadingOutlined, SearchOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import { config } from '~/config'
import { Flex } from '~/components'
import { AddNewB2BClientModal } from './AddNewB2BClientModal'
import { Link } from 'react-router-dom'
import { useUser } from '~/hooks/auth'

const columns = [
  {
    title: 'Company',
    dataIndex: 'clientCompanyName',
    key: 'clientCompanyName',
    sorter: (a, b) => a.clientCompanyName.localeCompare(b.clientCompanyName),
    render: (z, item, i) => {
      return (
        <Flex column alignStretch key={i}>
          <Link to={`/b2b-clients/details/${item.company}`}>{z}</Link>
        </Flex>
      )
    },
  },
  {
    title: 'Parameter',
    dataIndex: 'company',
    key: 'company',
  },
  {
    title: '# Licenses',
    dataIndex: 'licensesQty',
    key: 'licensesQty',
    sorter: (a, b) => a.licensesQty - b.licensesQty,
  },
  {
    title: '# Users',
    dataIndex: 'usersQty',
    key: 'usersQty',
    sorter: (a, b) => a.usersQty - b.usersQty,
  },
  {
    title: 'Date created',
    dataIndex: 'createdAt',
    key: 'createdAt',
    sorter: (a, b) => dayjs(a.createdAt).unix() - dayjs(b.createdAt).unix(),
    render: createdAt => dayjs(createdAt).format(config.DATE_FORMAT_FULL),
  },
]

export const ClientListTable = ({ clients = [], isLoading = true, isError = false, refetch }) => {
  const [value, setValue] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { user } = useUser()

  if (isLoading) {
    return <LoadingOutlined />
  }

  if (isError) {
    return <Button onClick={refetch}>Retry</Button>
  }

  const filteredClients = clients.filter(client =>
    client.clientCompanyName.toLowerCase().includes(value.toLowerCase())
  )

  return (
    <>
      <Flex justifyContent='space-between' alignItems='center' style={{ marginBottom: 20 }}>
        <Input
          name='searchQuery'
          placeholder='Search by company name'
          size='large'
          allowClear
          style={{ width: '400px' }}
          suffix={<SearchOutlined />}
          value={value}
          onChange={e => setValue(e.target.value)}
        />
        <Button
          type='primary'
          onClick={() => setIsModalOpen(true)}
          disabled={!user.permissions.some(p => p === 'client_write')}>
          + New Client
        </Button>
      </Flex>
      <Table
        loading={isLoading}
        dataSource={filteredClients}
        columns={columns}
        style={{ width: '100%' }}
      />
      <AddNewB2BClientModal open={isModalOpen} setOpen={setIsModalOpen} />
    </>
  )
}
