import React, { useState } from 'react'
import { Modal, Typography, Button, Input } from 'antd'
import { useLoading } from '~/hooks/use-loading'
import { toast } from 'react-toastify'
import { request } from '~/http'
import { Flex } from '~/components'
import { useParams } from 'react-router'

export const AddContactModal = ({ open, setOpen, contact, refetchCompany }) => {
  const { isLoading, enableLoading } = useLoading()
  const { company } = useParams()

  const [name, setName] = useState(contact.name)
  const [email, setEmail] = useState(contact.email)
  const [title, setTitle] = useState(contact.title)
  const [phone, setPhone] = useState(contact.phone)

  const handleCancel = () => {
    setName(contact.name)
    setEmail(contact.email)
    setTitle(contact.title)
    setPhone(contact.phone)

    setOpen(false)
  }

  const handleAddContact = enableLoading(async () => {
    try {
      await request('/clients/add-contact', {
        company,
        name,
        title,
        email,
        phone,
      })

      await refetchCompany()
      handleCancel()
    } catch (e) {
      toast.error(`Error adding client contact: ${e.message || e}`)
    }
  })

  return (
    <Modal
      open={open}
      onCancel={handleCancel}
      onOk={handleAddContact}
      footer={[
        <Button key='cancel' onClick={handleCancel}>
          Cancel
        </Button>,
        <Button key='add' disabled={isLoading} onClick={handleAddContact}>
          Save changes
        </Button>,
      ]}>
      <Typography.Title>Update Contact</Typography.Title>

      <Flex flexDirection='row' gap='24px'>
        <Input placeholder='Name' value={name} onChange={e => setName(e.target.value)} />
        <Input placeholder='Title' value={title} onChange={e => setTitle(e.target.value)} />
        <Input placeholder='Email' value={email} onChange={e => setEmail(e.target.value)} />
        <Input placeholder='Phone Number' value={phone} onChange={e => setPhone(e.target.value)} />
      </Flex>
    </Modal>
  )
}
