import React from 'react'
import _ from 'lodash'
import { Layout, Typography } from 'antd'
import { ClientListTable } from './ClientListTable'
import { useRequest } from '~/hooks/use-request'

import { useBreadcrumb } from '~/hooks/breadcrumbs'

export const ClientList = () => {
  useBreadcrumb(2, { label: 'Client List', path: '/b2b-clients/list' })

  const { data, error, isLoading, mutate } = useRequest('/clients/list', undefined, {
    swrOptions: {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      refreshInterval: 5000,
    },
  })

  return (
    <Layout.Content>
      <Typography.Title>B2B Clients List</Typography.Title>
      <ClientListTable clients={data?.clients} isLoading={isLoading} isError={error} refetch={mutate} />
    </Layout.Content>
  )
}
