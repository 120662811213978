import React, { useState } from 'react'
import _ from 'lodash'
import { toast } from 'react-toastify'
import { Layout, Modal, Result, Typography } from 'antd'

import { Flex, UserSearch } from '~/components'
import { request } from '~/http'
import { useBreadcrumb } from '~/hooks/breadcrumbs'
import { useDataTable } from '~/hooks/use-data-table'
import { UserTable } from '.'

const DEFAULT_REQUEST_ARGUMENTS = Object.freeze({
  query: '',
  limit: 20,
  skip: 0,
  current: 1,
  statuses: [],
})

export const UserList = () => {
  useBreadcrumb(2, { label: 'Manage Users', path: '/users/list' })

  const [userToDelete, setUserToDelete] = useState(null)
  const [userDeletionLoading, setUserDeletionLoading] = useState(false)

  const {
    data,
    error,
    isLoading,
    mutate: refetch,
    requestArguments,
    pagination,
    setRequestArguments,
    handleChange,
  } = useDataTable({
    path: '/user/list',
    defaultRequestArguments: DEFAULT_REQUEST_ARGUMENTS,
  })

  const handleChangeWithStatuses = (pagination, filters, sort) => {
    _.get(filters, 'status', [])

    handleChange(pagination, filters, sort)
  }

  const deleteUserRequest = async userId => {
    try {
      const { user } = await request('/user/delete-user', { userId })
      refetch()
      toast.success(`You successfully deleted ${user.username || user.email}`)
    } catch (err) {
      toast.error(err?.message)
    }
  }

  return (
    <Layout.Content>
      <Typography.Title>Manage Users</Typography.Title>

      <UserSearch
        onSubmit={({ searchQuery }) => {
          return setRequestArguments(prev => ({
            ...prev,
            query: searchQuery,
            current: prev.query !== searchQuery ? 1 : prev.current,
            skip: prev.query !== searchQuery ? 0 : prev.skip,
          }))
        }}
        isLoading={isLoading}
      />

      <UserTable
        setUserToDelete={setUserToDelete}
        loading={isLoading}
        users={data?.users}
        pagination={{ ...pagination, showSizeChanger: false }}
        totalCount={data?.totalCount}
        setListOptions={handleChangeWithStatuses}
      />

      <Modal
        data-testid='user-delete-modal'
        visible={!!userToDelete}
        onOk={async () => {
          setUserDeletionLoading(true)
          await deleteUserRequest(userToDelete._id)
          setUserToDelete(null)
          setUserDeletionLoading(false)
        }}
        okType='danger'
        okText='Confirm'
        okButtonProps={{ loading: userDeletionLoading }}
        onCancel={() => setUserToDelete(null)}>
        {userToDelete && (
          <Result
            status='warning'
            title={`Are you sure you wish to delete ${userToDelete.username || userToDelete.email}?`}
            subTitle={'All user data will be lost'}
          />
        )}
      </Modal>

      {error && (
        <Flex justifyCenter>
          <Typography.Text type='danger'>{error.message}</Typography.Text>
        </Flex>
      )}
    </Layout.Content>
  )
}
