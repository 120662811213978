import React, { useState } from 'react'
import { Typography, Button } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import { AddContactModal } from './AddContactModal'
import { useUser } from '~/hooks/auth'

export const ClientContactDetails = ({ contact, refetchCompany }) => {
  const [isOpen, setIsOpen] = useState(false)
  const { user } = useUser()

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid black',
        borderRadius: '8px',
        width: '100%',
        gap: '8px',
        padding: '24px',
      }}>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
        <Typography.Text>Contact</Typography.Text>
        <Button
          shape='circle'
          icon={<EditOutlined />}
          onClick={() => setIsOpen(true)}
          disabled={!user.permissions.some(p => p === 'client_write')}
        />
      </div>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
        <Typography.Text>{contact.name || 'No name provided'}</Typography.Text>
        <Typography.Text>{contact.email || 'No email provided'}</Typography.Text>
      </div>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
        <Typography.Text>{contact.title || 'No title provided'}</Typography.Text>
        <Typography.Text>{contact.phone || 'No phone provided'}</Typography.Text>
      </div>
      <AddContactModal
        contact={contact}
        open={isOpen}
        setOpen={setIsOpen}
        refetchCompany={refetchCompany}
      />
    </div>
  )
}
