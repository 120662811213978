import React from 'react'
import { Spin } from 'antd'
import { Flex } from './Flex'

export const Loader = ({ placeholder, height, ...props }) => {
  if (placeholder) {
    return (
      <Flex justifyCenter>
        <Spin {...props} />
      </Flex>
    )
  }
  return <Spin {...props} />
}
