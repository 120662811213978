import React from 'react'
import { Modal, Typography } from 'antd'
import { toast } from 'react-toastify'
import { request } from '~/http'
import { useLoading } from '~/hooks/use-loading'

export const ResetUserModal = ({ visible, user, hide, onSuccess }) => {
  const { isLoading, enableLoading } = useLoading()

  const sendReset = enableLoading(async () => {
    try {
      await request('/admin/reset-user', { userId: user.id })

      onSuccess()

      toast.success(`Successfully reset ${user.username}`)
    } catch (e) {
      toast.error(`The user could not be reset. Reason: ${e.message}`)
    }

    hide()
  })

  return (
    <Modal
      visible={visible}
      onCancel={hide}
      closable={false}
      maskClosable={false}
      onOk={sendReset}
      cancelButtonProps={{ disabled: isLoading }}
      okButtonProps={{ danger: true, loading: isLoading }}
      okText='Confirm Reset'>
      <Typography.Text>Are you sure you wish to reset {user.username}?</Typography.Text>
    </Modal>
  )
}
