import React from 'react'

import { Flex } from '~/components'
import { Typography, Table, Button } from 'antd'
import ReactJson from 'react-json-view'
import dayjs from 'dayjs'
import { config } from '~/config'

export const UserEventsTable = ({ events, isLoading, pagination, error, refetch, handleChange }) => {
  const USER_EVENTS_TABLE_COLUMNS = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: date => dayjs(date).format(config.DATE_FORMAT_FULL),
    },
    {
      title: 'Metadata',
      dataIndex: 'metadata',
      key: 'metadata',
      render: metadata =>
        metadata ? <ReactJson src={metadata} collapsed={true} enableClipboard={false} /> : '-',
      width: 417,
    },
  ]

  if (error) {
    return (
      <Flex column>
        <Typography.Text type='danger'>{error.message}</Typography.Text>
        <Button onClick={refetch}>Try Again</Button>
      </Flex>
    )
  }

  return (
    <Table
      data-testid='user-events-table'
      loading={isLoading}
      style={{ width: '100%' }}
      dataSource={events}
      columns={USER_EVENTS_TABLE_COLUMNS}
      onChange={handleChange}
      pagination={pagination}
    />
  )
}
