import React from 'react'
import { Button, Typography } from 'antd'
import { Container, Flex } from '~/components'
import { useAuth0 } from '@auth0/auth0-react'
import { getSelfUrl } from '~/utils/common'

export const Auth = () => {
  const { loginWithRedirect, isLoading } = useAuth0()

  if (isLoading) return null
  return (
    <Container>
      <Flex justifyCenter alignCenter column style={{ minHeight: 500 }}>
        <Typography.Title level={2} style={{ marginBottom: 10 }}>
          Audo Admin Panel
        </Typography.Title>
        <Typography.Text style={{ marginBottom: 25 }}>Please Log in to continue</Typography.Text>
        <Button
          onClick={() => {
            loginWithRedirect({ appState: { redirectUrl: `${getSelfUrl()}users/dashboard` } })
          }}>
          Login
        </Button>
      </Flex>
    </Container>
  )
}
