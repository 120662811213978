import React from 'react'
import { Typography, List, Input, Form, Divider, BackTop } from 'antd'
import { CloseSquareOutlined, CheckOutlined } from '@ant-design/icons'
import { Flex } from '~/components'
import { request } from '~/http'
import { useUser } from '~/hooks/auth'
import dayjs from 'dayjs'
import { config } from '~/config'
import { HistoryNotes } from './HistoryNotes'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { permissions } from '~/config/permissions'
import _ from 'lodash'

export const History = ({ historyItems, refetchHistory, historyLoading, historyError }) => {
  const { user: sessionUser } = useUser()

  const {
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
  })

  const loading = !historyItems && historyLoading

  if (loading) return <div>Loading...</div>

  if (historyError) return toast.error(historyError.message)

  return (
    <Flex column>
      <Typography.Title>Admin Activity</Typography.Title>

      <List
        data-testid={'history_list'}
        dataSource={historyItems}
        itemLayout='horizontal'
        bordered
        style={{ padding: '20px', width: '1000px' }}
        renderItem={(item, index) => (
          <>
            <Flex row justifyAround>
              <Flex column>
                <List.Item>
                  <List.Item.Meta
                    style={{ width: 250 }}
                    title={'Action'}
                    data-testid={item.property}
                    description={`${_.capitalize(item.property)} has been ${item.action}`}
                  />
                </List.Item>
                <List.Item>
                  <List.Item.Meta
                    style={{ width: 250 }}
                    title={'Date'}
                    description={dayjs(item.createdAt).format(config.DATE_FORMAT_FULL)}
                  />
                </List.Item>
                <List.Item>
                  <List.Item.Meta
                    style={{ width: 250 }}
                    title={'Username'}
                    data-testid={`${item.user}username`}
                    description={item.adminUser?.username ? item.adminUser.username : item.user.username}
                  />
                </List.Item>
              </Flex>
              <List.Item style={{ paddingRight: 100 }}>
                <HistoryNotes notes={item.notes} />
              </List.Item>
            </Flex>
            <Flex row justifyEnd>
              <Form>
                <Flex>
                  {sessionUser.isPermitted(permissions.user_write) ? (
                    <Form.Item
                      validateStatus={errors[index.toString()]?.message && 'error'}
                      help={errors[index.toString()]?.message}>
                      <Controller
                        control={control}
                        name={index.toString()}
                        render={({ field }) => (
                          <Input.TextArea
                            data-testid={'add-new-note-input'}
                            placeholder='Add new Note to this Admin Activity'
                            allowClear
                            maxLength={50}
                            showCount
                            bordered={true}
                            rows={2}
                            style={{ width: 300 }}
                            {...field}
                          />
                        )}
                      />
                    </Form.Item>
                  ) : null}

                  <List.Item
                    style={watch([index.toString()])[0] !== undefined ? {} : { visibility: 'hidden' }}
                    key={item._id}
                    actions={[
                      <a key='comment-cancel' onClick={() => reset()}>
                        <CloseSquareOutlined style={{ fontSize: '16px', color: 'red' }} />
                      </a>,
                      <a
                        role={'add-new-note'}
                        key='comment-submit'
                        onClick={handleSubmit(async newNote => {
                          try {
                            await request(
                              '/user/history/create-note',
                              { historyItemId: item._id, note: newNote[index] },
                              { user: sessionUser }
                            )
                            toast.success('New Note has been added')
                            reset()
                            refetchHistory()
                          } catch (error) {
                            toast.error(error.message)
                          }
                        })}>
                        <CheckOutlined style={{ fontSize: '16px', color: 'green' }} />
                      </a>,
                    ]}
                  />
                </Flex>
              </Form>
            </Flex>
            <Divider />
          </>
        )}
      />
      <BackTop />
    </Flex>
  )
}
