import React from 'react'
import { Button } from 'antd'
import { useAuth0 } from '@auth0/auth0-react'

export const LogoutButton = () => {
  const { logout } = useAuth0()
  const logOutAndNavigateToLogIn = () => {
    logout({ returnTo: window.location.origin })
  }

  return <Button onClick={logOutAndNavigateToLogIn}>Logout</Button>
}
