import React, { useState, useRef } from 'react'
import { CSVLink } from 'react-csv'
import { Link } from 'react-router-dom'
import { Table, Button } from 'antd'
import { Flex } from '~/components/Flex'
import dayjs from 'dayjs'
import { config } from '~/config'
import { DeleteOutlined, EditOutlined, DownloadOutlined } from '@ant-design/icons'
import { enhanceUser, useUser } from '~/hooks/auth'
import { userStatusConfigs } from '~/utils/user'
import { request } from '~/http'
import { toast } from 'react-toastify'
import _ from 'lodash'

const getColumns = setUserToDelete => [
  {
    title: '',
    key: 'actions',
    width: 20,
    render: (z, item, i) => {
      return (
        <Flex column alignStretch key={i} data-testid={`${i}-edit-action`}>
          <Link to={`/users/details/${item._id}`} state={{ user: item }}>
            <EditOutlined style={{ color: 'grey', fontSize: '25px' }} />
          </Link>
        </Flex>
      )
    },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    filters: Object.keys(userStatusConfigs).map(statusKey => ({
      text: userStatusConfigs[statusKey].label,
      value: statusKey,
    })),
    render: status => userStatusConfigs[status || 'enrolled']?.label,
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    sorter: true,
    render: (_, user) => enhanceUser(user).getFullName(),
  },
  {
    title: 'Username',
    dataIndex: 'username',
    key: 'username',
    sorter: true,
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    sorter: true,
  },
  {
    title: 'Last Seen',
    dataIndex: 'lastSeen',
    key: 'lastSeen',
    sorter: true,
    render: lastSeen => (!lastSeen ? null : dayjs(lastSeen).format(config.DATE_FORMAT_FULL)),
  },
  {
    title: 'Joined',
    dataIndex: 'createdAt',
    key: 'createdAt',
    sorter: true,
    render: createdAt => dayjs(createdAt).format(config.DATE_FORMAT_FULL),
  },
  {
    title: 'Company',
    dataIndex: 'company',
    key: 'company',
    sorter: false,
    filters: [
      {
        text: 'evolveme',
        value: 'evolveme',
      },
      {
        text: 'magicedtech',
        value: 'magicedtech',
      },
      {
        text: 'tks',
        value: 'tks',
      },
      {
        text: 'pars',
        value: 'pars',
      },
    ],
    render: company => company || '-',
  },
  {
    title: 'Roles',
    dataIndex: 'permissions',
    key: 'permissions',
    render: (z, item, i) => {
      return (
        <Flex column alignStretch key={i}>
          <p data-testid={`${item._id}-user-role`}>{enhanceUser(item).getRole()}</p>
        </Flex>
      )
    },
  },
  {
    title: '',
    key: 'delete',
    width: 20,
    requiredPermission: config.permissions.user_write,
    render: (z, item, i) => {
      return (
        <Flex column alignStretch key={i}>
          <DeleteOutlined
            data-testid={`${i}-delete-action`}
            style={{ color: 'red', fontSize: '25px' }}
            onClick={() => setUserToDelete(item)}
          />
        </Flex>
      )
    },
  },
]

const headers = [
  { label: 'First Name', key: 'firstName' },
  { label: 'Last Name', key: 'lastName' },
  { label: 'Email', key: 'email' },
  { label: 'Last Seen', key: 'lastSeen' },
  { label: 'Joined', key: 'joined' },
  { label: 'Guide Completed', key: 'skillQuizComplete' },
  { label: 'Email Verified', key: 'emailVerified' },
  { label: 'Auth ID', key: 'authId' },
  { label: 'Company', key: 'company' },
  { label: 'Current SkillTree', key: 'currentSkillTree' },
]

const setHeaders = (user, headers) => {
  const newHeaders = [...headers]
  const keys = Object.keys(user)

  keys.forEach(key => {
    if (headers.some(i => i.key.includes(key))) return
    if (typeof user[key] === 'function') return
    if (_.isPlainObject(user[key])) {
      const subKeys = Object.keys(user?.[key]) || []
      subKeys.forEach(subKey => {
        newHeaders.push({
          label: `${_.startCase(key) + ' - ' + _.startCase(subKey)}`,
          key: `${key}.${subKey}`,
        })
      })
    } else {
      newHeaders.push({ label: _.startCase(key), key })
    }
  })
  return newHeaders
}

export const UserTable = ({ users, pagination, setListOptions, loading, setUserToDelete }) => {
  const { user, isLoading: userLoading } = useUser()
  const [loadCSV, setLoadCSV] = useState(false)
  const [downloadData, setDownloadData] = useState([])
  const csvLink = useRef()

  const getDownloadData = async () => {
    setLoadCSV(true)
    try {
      const { users } = await request('/admin/user-report')
      setDownloadData(users)
      csvLink.current.link.click()
    } catch (e) {
      toast.error(`Error downloading users: ${e.message}`)
    }
    setLoadCSV(false)
  }

  return userLoading ? null : (
    <>
      <Flex justifyEnd style={{ paddingBottom: 25 }}>
        <Button
          loading={loadCSV}
          onClick={getDownloadData}
          type='primary'
          shape='round'
          icon={<DownloadOutlined />}
          size={24}>
          {' '}
          Export to CSV
        </Button>
      </Flex>
      <Table
        data-testid='users-table'
        loading={loading}
        style={{ width: '100%' }}
        dataSource={users}
        sortDirections={['ascend', 'descend']}
        onChange={setListOptions}
        columns={getColumns(setUserToDelete).filter(({ requiredPermission }) =>
          user.isPermitted(requiredPermission)
        )}
        pagination={pagination}
        rowKey={'email'}
      />
      <CSVLink
        data={downloadData}
        headers={headers}
        ref={csvLink}
        style={{ display: 'none' }}
        target='_blank'
        filename={`${dayjs(Date.now()).format(config.DATE_FORMAT_FULL)}_audo_users.csv`}
      />
    </>
  )
}
