import React from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { GlobalStyles } from './GlobalStyles'
import { Providers } from './Providers'
import { Router } from './Routes'

export const Application = () => (
  <Providers>
    <GlobalStyles />
    <Router />
    <ToastContainer />
  </Providers>
)
