import React, { useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import {
  AccountBookFilled,
  LockOutlined,
  LogoutOutlined,
  RocketFilled,
  UserOutlined,
  PictureOutlined,
} from '@ant-design/icons'
import { Layout, Menu, Popover } from 'antd'

import { enhanceUser, useUser } from '~/hooks/auth'
import { Flex, LogoutButton, Text } from '~/components'
import { config } from '~/config'
import dashboardAdminIcon from '~/assets/images/dashboard_admin_icon.png'
import { ProfileImageUploadModal } from '~/screens/users/details/ProfileImageUploadModal'
import { Avatar } from '~/components/Avatar'
import { mutateByPath } from '~/hooks/use-request'
import { Hamburger } from './Hamburger'
import { ProfileImageDeletionModal } from '~/screens/users/details/ProfileImageDeletionModal'
import { useAuth0 } from '@auth0/auth0-react'

const MenuItemButton = styled.button`
  border: none;
  background: none;
  padding: 0;
`

const PopOverContent = ({
  user,
  isDeleteAvatarDisabled,
  showChangeProfileImageModal,
  showProfileImageDeletionModal,
}) => (
  <Menu>
    <Menu.Item key={user.email}>
      <Text medium>You are logged in as: {user.email}</Text>
    </Menu.Item>

    <Menu.Item key={'Change Profile Picture'} icon={<UserOutlined />}>
      <MenuItemButton
        onClick={event => {
          event.preventDefault()
          showChangeProfileImageModal()
        }}>
        Change Profile Picture
      </MenuItemButton>
    </Menu.Item>

    <Menu.Item key={'Delete Avatar'} icon={<PictureOutlined />} disabled={isDeleteAvatarDisabled}>
      <MenuItemButton
        disabled={isDeleteAvatarDisabled}
        onClick={event => {
          event.preventDefault()
          showProfileImageDeletionModal()
        }}>
        Delete Avatar
      </MenuItemButton>
    </Menu.Item>

    <Menu.Item key={'ChangePassword'} icon={<LockOutlined />}>
      <MenuItemButton>Change Password</MenuItemButton>
    </Menu.Item>

    <Menu.Item key={'Permission'} icon={<AccountBookFilled />}>
      <MenuItemButton>Permissions</MenuItemButton>
    </Menu.Item>

    <Menu.Item key={'Logout'} icon={<LogoutOutlined />}>
      <LogoutButton />
    </Menu.Item>
  </Menu>
)

const FlexThird = styled(Flex)`
  flex: 1 1 33.33%;
`

export const Header = () => {
  const { user } = useUser()
  const { isAuthenticated: isLoggedIn } = useAuth0()

  const [profileImageUploadIsVisible, setProfileImageUploadIsVisible] = useState(false)
  const [profileImageDeletionIsVisible, setProfileImageDeletionIsVisible] = useState(false)

  const { url, isDefault } = enhanceUser(user).getAvatar()

  return (
    <Layout.Header>
      <Flex>
        <FlexThird>{isLoggedIn && <Hamburger />}</FlexThird>

        <FlexThird justifyCenter>
          <Link to='/'>
            <Flex alignCenter>
              <img style={{ height: '30px', marginRight: '20px' }} src={dashboardAdminIcon} />
              <Text bold inline light>
                Audo Admin Panel
              </Text>
            </Flex>
          </Link>
        </FlexThird>

        <FlexThird justifyEnd>
          {isLoggedIn ? (
            <Flex alignCenter>
              <Text bold inline style={{ marginRight: '30px', display: 'flex', alignItems: 'center' }}>
                <RocketFilled style={{ color: 'blueviolet', marginRight: '8px' }} />

                <a
                  rel='noreferrer'
                  target='_blank'
                  href={config.CLIENT_APP_URL}
                  style={{ color: 'white' }}>
                  Audo Learn Live
                </a>
              </Text>

              <Popover
                placement='bottomLeft'
                content={
                  <PopOverContent
                    user={user}
                    showChangeProfileImageModal={() => setProfileImageUploadIsVisible(true)}
                    showProfileImageDeletionModal={() => setProfileImageDeletionIsVisible(true)}
                    isDeleteAvatarDisabled={isDefault}
                  />
                }
                trigger={['click']}>
                <Link to={''} className='ant-dropdown-link' onClick={e => e.preventDefault()}>
                  <Avatar
                    size={50}
                    imageUrl={url}
                    clickable={false}
                    isLoading={user.isEmpty()}
                    onClick={() => setProfileImageUploadIsVisible(true)}
                  />
                </Link>
              </Popover>
            </Flex>
          ) : null}
        </FlexThird>

        <ProfileImageUploadModal
          user={user}
          visible={profileImageUploadIsVisible}
          hide={() => setProfileImageUploadIsVisible(false)}
          onSuccess={() => {
            mutateByPath('/user/history/list')
            mutateByPath('/user/details')
            mutateByPath('/user/me')
          }}
        />

        <ProfileImageDeletionModal
          user={user}
          visible={profileImageDeletionIsVisible}
          hide={() => setProfileImageDeletionIsVisible(false)}
          onSuccess={() => {
            mutateByPath('/user/history/list')
            mutateByPath('/user/details')
            mutateByPath('/user/me')
          }}
        />
      </Flex>
    </Layout.Header>
  )
}
