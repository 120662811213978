import React, { useState } from 'react'
import { Layout, Typography, Button, Spin } from 'antd'
import { useRequest } from '~/hooks/use-request'
import { UserSearch } from '~/components'
import { AddFreeAccessUserModal } from './AddFreeAccessUserModal'
import { FreeUserTable } from './FreeUserTable'

export const FreeAccessUserList = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [filter, setFilter] = useState('')

  const { data, isLoading, error, mutate } = useRequest('/admin/free-access/list')

  const listOfFreeAccessUsers = data?.freeAccessUserList || []

  const filteredFreeAccessList = listOfFreeAccessUsers.filter(userObj => userObj.email.includes(filter))

  if (error) {
    return (
      <Layout.Content>
        <Typography.Text type='danger'>Error loading Free Access Users</Typography.Text>
        <Button onClick={mutate} style={{ marginLeft: '24px' }}>
          Try again
        </Button>
      </Layout.Content>
    )
  }

  return (
    <Layout.Content>
      <Typography.Title>Manage Free Access Users</Typography.Title>
      <Button style={{ marginBottom: '24px' }} onClick={() => setIsModalOpen(true)}>
        Grant User Free Access
      </Button>
      <UserSearch
        onSubmit={({ searchQuery }) => {
          setFilter(searchQuery)
        }}
      />
      {isLoading ? (
        <Spin />
      ) : (
        <FreeUserTable users={filteredFreeAccessList} error={error} refetch={mutate} />
      )}
      <AddFreeAccessUserModal open={isModalOpen} setOpen={setIsModalOpen} freeAccessUserList={mutate} />
    </Layout.Content>
  )
}
