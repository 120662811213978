import { Typography, Button, Spin } from 'antd'
import React from 'react'
import { Routes, Route, Navigate } from 'react-router'
import { Sidebar, Flex, Container, Loader } from '~/components'
import { NotFound } from '~/screens'
import { permissions } from '~/config/permissions'
import { useUser } from '~/hooks/auth'
import { useBreadcrumb } from '~/hooks/breadcrumbs'
import { Dashboard } from './dashboard'
import { UserDetails } from './details'
import { UserWhitelist } from './whitelist'
import { UserList } from './list'
import { toast } from 'react-toastify'
import { request } from '~/http'
import { useLoading } from '~/hooks/use-loading'
import { FreeAccessUserList } from './freeUserList'

const sidebarLinks = [
  {
    path: '/dashboard',
    label: 'Dashboard',
  },
  {
    path: '/list',
    label: 'Manage Users',
  },
  {
    path: '/whitelist',
    label: 'Manage Whitelist',
  },
  {
    path: '/free-access-users',
    label: 'Manage Free Access Users',
  },
]

export const UsersModule = () => {
  useBreadcrumb(1, { label: 'Users', path: '/users' })

  const { user, isLoading } = useUser()
  const { isLoading: isLoadingDeleteTestAccounts, enableLoading } = useLoading()

  if (isLoading) return <Loader placeholder />

  if (user.isRestricted(permissions.user_read)) {
    return (
      <Flex justifyCenter>
        <Typography.Text>Missing permissions to access this module</Typography.Text>
      </Flex>
    )
  }

  const handleDeleteTestAccounts = enableLoading(async () => {
    try {
      await request('/admin/delete-test-users')
      toast.success('Successfully deleted test accounts')
    } catch (e) {
      toast.error('The test accounts could not be deleted.')
    }
  })

  return (
    <Container>
      <Flex noWrap>
        <Flex column>
          <Sidebar items={sidebarLinks} root='/users' />
          <Button
            onClick={handleDeleteTestAccounts}
            disabled={isLoadingDeleteTestAccounts}
            style={{ width: 175 }}>
            {isLoadingDeleteTestAccounts ? <Spin /> : 'Delete test accounts'}
          </Button>
        </Flex>

        <Routes>
          <Route index element={<Navigate to='./dashboard' />} />
          <Route path='dashboard' element={<Dashboard />} />
          <Route path='list' element={<UserList />} />
          <Route path='whitelist' element={<UserWhitelist />} />
          <Route path='details/:userId' element={<UserDetails />} />
          <Route path='free-access-users' element={<FreeAccessUserList />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
      </Flex>
    </Container>
  )
}
