import styled from 'styled-components'
import stylePropType from 'react-style-proptype'
import { PropTypes } from 'prop-types'

export const Column = styled.div`
  width: ${props => {
    if (props.third) return '33%'
    if (props.quarter) return '25%'
    if (props.fifth) return '20%'
    if (props.threeQuarter) return '75%'
    if (props.twoThird) return '66%'
    return '50%'
  }};
  padding: 15px;
  position: relative;
  flex-grow: 1;
  flex-shrink: 1;

  @media (max-width: 725px) {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
`

Column.propTypes = {
  style: stylePropType,
  third: PropTypes.bool,
  quarter: PropTypes.bool,
  fifth: PropTypes.bool,
  threeQuarter: PropTypes.bool,
  twoThird: PropTypes.bool,
}
