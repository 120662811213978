import React, { useEffect } from 'react'
import { BrowserRouter, Route, Routes as RRRoutes } from 'react-router-dom'

import { config } from '~/config'
import { Auth } from '~/screens/auth/Auth'
import { Layout } from './Layout/Layout'

import { NotFound } from '~/screens/NotFound'
import { AdminsModule } from '~/screens/admins'
import { UsersModule } from '~/screens/users'
import { PartnersModule } from '~/screens/partners'
import { Home } from '~/screens/Home'
import { useBreadcrumb } from '~/hooks/breadcrumbs'
import { GamificationModule } from '~/screens/gamification'
import { useAuth0 } from '@auth0/auth0-react'
import { Loader } from '~/components'
import { useSWRConfig } from 'swr'
import { getRequestKey } from '~/hooks/use-request'
import { useUser } from '~/hooks/auth'
import { B2BClientsModule } from '~/screens/b2b-clients'

export const Router = ({ children }) => {
  useBreadcrumb(0, { label: 'Home', path: '/' })
  const { mutate } = useSWRConfig()

  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0()
  async function fat() {
    const token = await getAccessTokenSilently()
    localStorage.setItem('accessToken', token)
    mutate(getRequestKey('/user/me'))
  }
  useEffect(() => {
    if (isAuthenticated) {
      fat()
    } else {
      localStorage.removeItem('accessToken')
    }
  }, [isAuthenticated])

  const { isLoading: userLoading } = useUser()

  if (isLoading || userLoading) return <Loader placeholder style={{ margin: 50 }} />

  if (!isAuthenticated) return <Auth />

  return (
    <BrowserRouter basename={config.ROUTER_PREFIX}>
      <Routes />
      {children}
    </BrowserRouter>
  )
}

export const Routes = () => (
  <RRRoutes>
    <Route path='/' element={<Layout />}>
      <Route index element={<Home />} />
      <Route path='admins/*' element={<AdminsModule />} />
      <Route path='users/*' element={<UsersModule />} />
      <Route path='b2b-clients/*' element={<B2BClientsModule />} />
      <Route path='gamification/*' element={<GamificationModule />} />
      <Route path='partners/*' element={<PartnersModule />} />
    </Route>

    <Route path='*' element={<NotFound includeLayout />} />
  </RRRoutes>
)
