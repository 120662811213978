import { Typography } from 'antd'
import React from 'react'

import { Container, Flex } from '~/components'
import { useUser } from '~/hooks/auth'

export const Home = () => {
  const { user } = useUser()

  if (!user.permissions.includes('admin_read') && !user.permissions.includes('admin_write')) {
    return (
      <Container>
        <Flex column alignCenter>
          <Typography.Title style={{ textAlign: 'center' }}>You're not an admin!</Typography.Title>
          <Typography.Text style={{ textAlign: 'center' }}>
            You will be logged out shortly.
          </Typography.Text>
        </Flex>
      </Container>
    )
  }

  return (
    <Container>
      <Flex column alignCenter>
        <Typography.Title style={{ textAlign: 'center' }}>Welcome, {user.email}!</Typography.Title>
        <Typography.Text style={{ textAlign: 'center' }}>{"You're an admin 🙂"}</Typography.Text>
      </Flex>
    </Container>
  )
}
