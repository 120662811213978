import React from 'react'
import { Routes, Route, Navigate } from 'react-router'
import { Container } from '~/components/Container'
import { Flex } from '~/components/Flex'
import { Sidebar } from '~/components/Sidebar'
import { useBreadcrumb } from '~/hooks/breadcrumbs'
import { EventsList } from './events'
import { EventDetails } from '~/screens/gamification/event-details/EventDetails'

const sidebarLinks = [
  {
    path: '/list',
    label: 'Events',
  },
]

export const GamificationModule = () => {
  useBreadcrumb(1, { label: 'Gamification', path: '/gamification' })

  return (
    <Container>
      <Flex noWrap>
        <Sidebar items={sidebarLinks} />
        <Routes>
          <Route path='/list' element={<EventsList />} />
          <Route path='/event-details/:eventType' element={<EventDetails />} />
          <Route path='*' element={<Navigate to='list' />} />
        </Routes>
      </Flex>
    </Container>
  )
}
