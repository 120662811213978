import React from 'react'
import { Layout, Typography } from 'antd'
import { useBreadcrumb } from '~/hooks/breadcrumbs'
import { toast } from 'react-toastify'
import { EventsTable } from './'
import { useRequest } from '~/hooks/use-request'
import { useUser } from '~/hooks/auth'
import { permissions } from '~/config/permissions'
import { Flex, Loader } from '~/components'

export const EventsList = () => {
  useBreadcrumb(2, { label: 'Events', path: '/gamification/list' })
  const { user: sessionUser } = useUser()

  // NOTE: sort feature functioanlity is prepared in the commented sections

  // const [currentSort, setCurrentSort] = useState()
  // const [requestArguments, setRequestArguments] = useState({
  //   sort: '',
  // })

  const { data, error, isLoading } = useRequest('/gamification/event-types')

  // const setSortArguments = arg => {
  //   let sort = null
  //   if (arg.order === 'ascend') {
  //     sort = { [arg.field]: 'asc' }
  //   } else if (arg.order === 'descend') {
  //     sort = { [arg.field]: 'desc' }
  //   }

  //   setRequestArguments({ sort })
  // }

  // const handleChange = (pageNumber, filters, sort) => {
  //   if (!_.isEqual(currentSort, sort) && !_.isEmpty(sort)) {
  //     setCurrentSort(sort)
  //     setSortArguments(sort)
  //   }
  // }

  const isAllowed =
    sessionUser.isPermitted(permissions.gamification_read) ||
    sessionUser.isPermitted(permissions.gamification_write)

  if (isLoading) return <Loader placeholder />
  if (error && isAllowed) return toast.error(error.message)

  return (
    <Layout.Content>
      {isAllowed ? (
        <>
          <Typography.Title>Events</Typography.Title>

          <EventsTable events={data?.eventTypes} />
        </>
      ) : (
        <Flex justifyCenter style={{ width: '80%' }}>
          <Typography.Text>Missing permissions to access this module</Typography.Text>
        </Flex>
      )}
    </Layout.Content>
  )
}
