import React from 'react'
import { Button, Spin, DatePicker, Table } from 'antd'
import { toast } from 'react-toastify'
import { request } from '~/http'
import { useLoading } from '~/hooks/use-loading'
import dayjs from 'dayjs'
import moment from 'moment'

export const FreeUserTable = ({ users, refetch }) => {
  const { isLoading, enableLoading } = useLoading()

  const handleRemoveFreeAccess = enableLoading(async email => {
    try {
      await request('/admin/free-access/revoke-user', {
        email,
      })
      await refetch()
      toast.success('Successfully revoked free access from user.')
    } catch (e) {
      toast.error('Something went wrong. Please try again.')
    }
  })

  const handleChangeExpirationDate = enableLoading(async (email, dateString) => {
    try {
      await request('/admin/free-access/update-expiration', {
        email,
        expirationDate: dateString,
      })
      await refetch()
      toast.success('Successfully updated expiration date')
    } catch (e) {
      toast.error('Error updating expiration date')
    }
  })
  const FREE_USER_TABLE_COLUMNS = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Expiration Date',
      dataIndex: 'expirationDate',
      key: 'expirationDate',
      render: (expirationDate, userObj) => {
        const date = dayjs(expirationDate).toISOString()

        return (
          <DatePicker
            data-testid={`${userObj.email}-expiration-date`}
            allowClear={false}
            defaultValue={moment(date, 'YYYY-MM-DD')}
            onChange={async (date, dateString) => {
              await handleChangeExpirationDate(userObj.email, dateString)
            }}
          />
        )
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, userObj, index) => (
        <Button
          data-testid={`${index}-revoke-free-access-button`}
          type='danger'
          disabled={isLoading}
          onClick={() => handleRemoveFreeAccess(userObj.email)}>
          {isLoading ? <Spin /> : 'Revoke Free Access'}
        </Button>
      ),
    },
  ]

  return (
    <Table
      data-testid='free-access-user-list'
      columns={FREE_USER_TABLE_COLUMNS}
      dataSource={users}
      pagination={false}
      rowKey='email'
      style={{ width: '100%' }}
      freeAccessUserList={refetch}
    />
  )
}
