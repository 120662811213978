/* eslint-disable no-nested-ternary */
import { commonConfig } from './common'

export const courseraConfig = {
  adminPanelUrl:
    commonConfig.ENV === 'production'
      ? 'https://www.coursera.org/o/audo/admin/home'
      : commonConfig.ENV === 'staging'
      ? 'https://www.coursera.org/o/audo-staging/admin/home'
      : 'https://www.coursera.org/o/audo-sandbox/admin/home',
}
