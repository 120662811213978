/* istanbul ignore file */
import styled from 'styled-components'
import { PropTypes } from 'prop-types'
import stylePropType from 'react-style-proptype'

export const Flex = styled.div`
  display: ${props => (props.inline ? 'inline-flex' : 'flex')};
  flex-direction: ${props => {
    if (props.columnReverse) return 'column-reverse'
    if (props.rowReverse) return 'row-reverse'
    if (props.column) return 'column'
    return 'row'
  }};
  flex-wrap: ${props => {
    if (props.wrapReverse) return 'wrap-reverse'
    else if (props.noWrap) return 'nowrap'
    // Defaults to nowrap if it's column,
    // Otherwise it'd cause unexpected behaviors.
    // This is because we naturally expect column contents to expand downwards,
    // and it is extremely rare that we're gonna wrap columns.
    if (props.column) return 'nowrap'
    return 'wrap'
  }};
  justify-content: ${props => {
    if (props.justifyContent) return props.justifyContent
    if (props.justifyCenter) return 'center'
    else if (props.justifyAround) return 'space-around'
    else if (props.justifyBetween) return 'space-between'
    else if (props.justifyEnd) return 'flex-end'
    return 'flex-start'
  }};
  align-items: ${props => {
    if (props.alignItems) return props.alignItems
    else if (props.alignStretch) return 'stretch'
    else if (props.alignEnd) return 'flex-end'
    if (props.alignCenter) return 'center'
    else if (props.alignBaseline) return 'baseline'
    return 'flex-start'
  }};
  align-content: ${props => {
    if (props.alignContent) return props.content
    else if (props.contentStart) return 'flex-start'
    else if (props.contentEnd) return 'flex-end'
    else if (props.contentCenter) return 'center'
    else if (props.contentBetween) return 'space-between'
    else if (props.contentAround) return 'space-around'
    return 'stretch'
  }};
  gap: ${props => props.gap};
`

Flex.propTypes = {
  style: stylePropType,
  columnReverse: PropTypes.bool,
  rowReverse: PropTypes.bool,
  column: PropTypes.bool,
  wrapReverse: PropTypes.bool,
  noWrap: PropTypes.bool,
  justifyContent: PropTypes.oneOf(['flex-start', 'flex-end', 'space-between', 'space-around', 'center']),
  justifyEnd: PropTypes.bool,
  justifyBetween: PropTypes.bool,
  justifyAround: PropTypes.bool,
  justifyCenter: PropTypes.bool,
  alignItems: PropTypes.oneOf(['flex-start', 'flex-end', 'stretch', 'baseline', 'center']),
  alignStretch: PropTypes.bool,
  alignEnd: PropTypes.bool,
  alignCenter: PropTypes.bool,
  alignBaseline: PropTypes.bool,
  alignContent: PropTypes.oneOf([
    'stretch',
    'flex-start',
    'flex-end',
    'space-between',
    'space-around',
    'center',
  ]),
  contentStart: PropTypes.bool,
  contentEnd: PropTypes.bool,
  contentCenter: PropTypes.bool,
  contentBetween: PropTypes.bool,
  contentAround: PropTypes.bool,
}
