import React, { useState } from 'react'
import { Modal, Typography, Input, Button } from 'antd'
import { useLoading } from '~/hooks/use-loading'
import { toast } from 'react-toastify'
import { request } from '~/http'

export const AddWhitelistUserModal = ({ open, setOpen, fetchWhitelistedUsers }) => {
  const { isLoading, enableLoading } = useLoading()
  const [input, setInput] = useState('')

  const handleAddUserToWhitelist = enableLoading(async () => {
    try {
      const emails = input
        .trim()
        .split(/\r?\n/)
        .filter(email => email.length > 0)

      if (emails.length > 0) {
        await request('/admin/whitelist/add-users', {
          emails,
        })

        await fetchWhitelistedUsers()

        toast.success('Successfully added users to whitelist')
        setInput('')
        setOpen(false)
        return
      }
      toast.error('No users were added to whitelist')
    } catch (e) {
      toast.error('Could not add users to whitelist.')
    }
  })

  const handleCancel = () => {
    setInput('')
    setOpen(false)
  }

  return (
    <Modal
      open={open}
      onCancel={handleCancel}
      onOk={handleAddUserToWhitelist}
      footer={[
        <Button key='cancel' onClick={handleCancel}>
          Cancel
        </Button>,
        <Button key='add' onClick={handleAddUserToWhitelist} disabled={input === '' || isLoading}>
          Add Users
        </Button>,
      ]}>
      <Typography.Title>Add User to Whitelist</Typography.Title>
      <Typography.Text>
        Enter the emails of the users you wish to add to the whitelist. One email for each line.
      </Typography.Text>
      <Input.TextArea
        autoSize={{ minRows: 6, maxRows: 6 }}
        onChange={e => setInput(e.target.value)}
        value={input}
        disabled={isLoading}
        style={{ marginTop: '24px' }}
      />
    </Modal>
  )
}
