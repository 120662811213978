import React from 'react'
import PropTypes from 'prop-types'
import { Controller, useForm } from 'react-hook-form'
import { Form, Input } from 'antd'
import { SearchOutlined } from '@ant-design/icons'

export const UserSearch = ({ onSubmit, isLoading }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
  })

  return (
    <Form>
      <Form.Item
        name='searchQuery'
        validateStatus={errors?.searchQuery?.message && 'error'}
        help={errors?.searchQuery?.message}>
        <Controller
          name='searchQuery'
          control={control}
          render={({ field }) => (
            <Input.Search
              name='searchQuery'
              placeholder='Search by name, email, or username'
              enterButton='Search'
              size='large'
              allowClear
              loading={isLoading}
              style={{ width: '400px' }}
              prefix={<SearchOutlined />}
              onSearch={value => handleSubmit(onSubmit({ searchQuery: value }))}
              {...field}
            />
          )}
        />
      </Form.Item>
    </Form>
  )
}

UserSearch.propTypes = {
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
}
