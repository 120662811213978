import React, { forwardRef } from 'react'
import styled from 'styled-components'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

const ReactQuillWrapper = styled.div`
  .ql-container {
    height: 200px;
  }

  .ql-container.ql-snow,
  .ql-toolbar.ql-snow {
    border-color: ${({ hasError }) => (hasError ? '#ff4d4f' : '#d9d9d9')};
  }

  .ql-editor.ql-blank::before {
    color: rgb(200, 200, 200);
    font-size: 14px;
    font-style: initial;
    font-weight: 300;
  }
`

export const Wysiwyg = forwardRef(({ hasError, ...otherProps }, ref) => (
  <ReactQuillWrapper hasError={hasError}>
    <ReactQuill ref={ref} theme='snow' {...otherProps} />
  </ReactQuillWrapper>
))
