import React, { useState } from 'react'
import { Layout, Typography, Button, Divider, Spin } from 'antd'
import { useRequest } from '~/hooks/use-request'
import { Flex, UserSearch } from '~/components'
import { toast } from 'react-toastify'
import { request } from '~/http'
import { useLoading } from '~/hooks/use-loading'
import { AddWhitelistUserModal } from './AddWhitelistUserModal'

export const UserWhitelist = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [filter, setFilter] = useState('')

  const { data, isLoading, error, mutate } = useRequest('/admin/whitelist/list-users')

  const whitelist = data?.whitelist || []

  const filteredWhitelist = whitelist.filter(email => email.includes(filter))

  if (error) {
    return (
      <Layout.Content>
        <Typography.Text type='danger'>Error loading whitelist</Typography.Text>
        <Button onClick={mutate} style={{ marginLeft: '24px' }}>
          Try again
        </Button>
      </Layout.Content>
    )
  }

  return (
    <Layout.Content>
      <Typography.Title>Manage Whitelist</Typography.Title>

      <Button style={{ marginBottom: '24px' }} onClick={() => setIsModalOpen(true)}>
        Add User to Whitelist
      </Button>

      <UserSearch
        onSubmit={({ searchQuery }) => {
          setFilter(searchQuery)
        }}
      />

      {isLoading ? (
        <Spin />
      ) : (
        <Flex column>
          {filteredWhitelist.map((email, index) => (
            <WhitelistedUser key={index} email={email} index={index} fetchWhitelistedUsers={mutate} />
          ))}
        </Flex>
      )}
      <AddWhitelistUserModal
        open={isModalOpen}
        setOpen={setIsModalOpen}
        fetchWhitelistedUsers={mutate}
      />
    </Layout.Content>
  )
}

const WhitelistedUser = ({ email, index, fetchWhitelistedUsers }) => {
  const { isLoading, enableLoading } = useLoading()

  const handleRemoveWhitelist = enableLoading(async () => {
    try {
      await request('/admin/whitelist/remove-users', {
        emails: [email],
      })
      await fetchWhitelistedUsers()
      toast.success('Successfully removed whitelisted user')
    } catch (e) {
      toast.error('The user could not be removed from whitelist.')
    }
  })

  return (
    <Flex alignCenter style={{ width: '100%' }}>
      <Typography.Text>{`${index + 1} - ${email}`}</Typography.Text>
      <Button
        type='danger'
        style={{ marginLeft: 'auto', width: 187 }}
        onClick={handleRemoveWhitelist}
        disabled={isLoading}>
        {isLoading ? <Spin /> : 'Remove from whitelist'}
      </Button>
      <Divider />
    </Flex>
  )
}
