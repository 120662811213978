import { Spin } from 'antd'
import React from 'react'
import { Navigate, Outlet, useLocation } from 'react-router'
import { Container } from '~/components/Container'
import { Flex } from '~/components/Flex'
import { useUser } from '~/hooks/auth'
import { Breadcrumbs } from './Breadcrumbs'

import { Header } from './Header'

export const Layout = ({ children }) => {
  const { isLoading } = useUser()
  const location = useLocation()

  if (isLoading) {
    return (
      <Container>
        <Flex justifyCenter>
          <Spin />
        </Flex>
      </Container>
    )
  }

  return (
    <div>
      <Header />
      <Breadcrumbs />
      {children}
      <Outlet />
    </div>
  )
}
