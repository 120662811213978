import React from 'react'
import { Modal, Typography } from 'antd'
import { request } from '~/http'
import { toast } from 'react-toastify'
import { useLoading } from '~/hooks/use-loading'

export const ProfileImageDeletionModal = ({ visible, user, hide, onSuccess }) => {
  const { isLoading, enableLoading } = useLoading()

  const submitDeletion = enableLoading(async () => {
    try {
      await request('/user/delete-profile-image', { userId: user._id })

      onSuccess()

      toast.success(`Successfully deleted profile image for ${user.username}`)
    } catch (e) {
      toast.error(`The profile picture could not be deleted. Reason: ${e.message}`)
    }

    hide()
  })

  return (
    <Modal
      visible={visible}
      onCancel={hide}
      closable={false}
      maskClosable={false}
      onOk={submitDeletion}
      cancelButtonProps={{ disabled: isLoading }}
      okButtonProps={{ danger: true, loading: isLoading }}
      okText='OK'>
      <Typography.Text>
        Are you sure you want to delete this image? We will replace it with the default profile picture.
      </Typography.Text>
    </Modal>
  )
}
