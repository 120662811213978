import React from 'react'
import { Text } from '~/components'
import { Container } from '~/components/Container'
import { Flex } from '~/components/Flex'
import { useBreadcrumb } from '~/hooks/breadcrumbs'

export const AdminsModule = () => {
  useBreadcrumb(1, { label: 'Administrators', path: '/admins' })

  return (
    <Container>
      <Flex noWrap>
        <Text>Moved to Auth0 Web Dashboard</Text>
      </Flex>
    </Container>
  )
}
