import React, { useState } from 'react'
import { Button, Typography, Input, Table } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { AddUsersToClientModal } from './AddUsersToClientModal'
import { AddAdminToClientModal } from './AddAdminToClientModal'
import { RemoveUsersFromClientModal } from './RemoveUsersFromClientModal'
import dayjs from 'dayjs'
import { config } from '~/config'
import { Flex } from '~/components'
import { Link } from 'react-router-dom'
import { useUser } from '~/hooks/auth'

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    sorter: (a, b) => a?.name?.localeCompare(b?.name),
    render: name => name || '-',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    sorter: (a, b) => a?.email?.localeCompare(b?.email),
    render: (z, item, i) => {
      return (
        <Flex column alignStretch key={i}>
          <Link to={`/users/details/${item.id}`}>{z}</Link>
        </Flex>
      )
    },
  },
  {
    title: 'Company',
    dataIndex: 'company',
    key: 'company',
  },
  {
    title: 'Last Seen',
    dataIndex: 'lastSeen',
    key: 'lastSeen',
    sorter: (a, b) => dayjs(a?.lastSeen).unix() - dayjs(b?.lastSeen).unix(),
    render: lastSeen => (lastSeen ? dayjs(lastSeen).format(config.DATE_FORMAT_FULL) : '-'),
  },
  {
    title: 'Joined',
    dataIndex: 'createdAt',
    key: 'createdAt',
    sorter: (a, b) => dayjs(a?.createdAt).unix() - dayjs(b?.createdAt).unix(),
    render: createdAt => (createdAt ? dayjs(createdAt).format(config.DATE_FORMAT_FULL) : '-'),
  },
]

export const ClientUsers = ({ users, isLoading, refetchCompany }) => {
  const [value, setValue] = useState('')
  const [isAddUsersToClientModalOpen, setIsAddUsersToClientModalOpen] = useState(false)
  const [isAddAdminToClientModalOpen, setIsAddAdminToClientModalOpen] = useState(false)
  const [isRemoveUsersFromClientModalOpen, setIsRemoveUsersFromClientModalOpen] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const { user } = useUser()

  const rowSelection = {
    selectedRowKeys,
    onChange: val => {
      setSelectedRowKeys(val)
    },
  }

  const filteredUsers = users
    ?.filter(user => user.email.toLowerCase().includes(value.toLowerCase()))
    .map(item => ({
      ...item,
      key: item.email,
    }))

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginTop: '32px',
      }}>
      <Typography.Title>Users</Typography.Title>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '24px',
        }}>
        <Input
          name='searchQuery'
          placeholder='Search users'
          size='large'
          allowClear
          style={{ width: '400px' }}
          suffix={<SearchOutlined />}
          value={value}
          onChange={e => setValue(e.target.value)}
        />
        <div
          style={{
            display: 'flex',
            gap: '12px',
          }}>
          <Button
            type='secondary'
            onClick={() => setIsRemoveUsersFromClientModalOpen(true)}
            disabled={selectedRowKeys.length === 0 || !user.permissions.some(p => p === 'client_write')}>
            Remove Users
          </Button>
          <Button
            type='primary'
            onClick={() => setIsAddUsersToClientModalOpen(true)}
            disabled={!user.permissions.some(p => p === 'client_write')}>
            + New Users
          </Button>
          <Button
            type='primary'
            onClick={() => setIsAddAdminToClientModalOpen(true)}
            disabled={!user.permissions.some(p => p === 'client_write')}>
            + Add admin
          </Button>
        </div>
      </div>

      <Table
        rowSelection={{
          ...rowSelection,
        }}
        loading={isLoading}
        dataSource={filteredUsers}
        columns={columns}
      />
      <AddUsersToClientModal
        open={isAddUsersToClientModalOpen}
        setOpen={setIsAddUsersToClientModalOpen}
        fetchDetails={refetchCompany}
      />
      <AddAdminToClientModal
        open={isAddAdminToClientModalOpen}
        setOpen={setIsAddAdminToClientModalOpen}
        fetchDetails={refetchCompany}
      />
      <RemoveUsersFromClientModal
        open={isRemoveUsersFromClientModalOpen}
        setOpen={setIsRemoveUsersFromClientModalOpen}
        usersToRemove={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
        refetchDetails={refetchCompany}
      />
    </div>
  )
}
