import React, { useRef } from 'react'
import { Button, Form, Input, Modal, Typography } from 'antd'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { request } from '~/http'
import { Flex, Wysiwyg } from '~/components'
import { useLoading } from '~/hooks/use-loading'

const schema = yup.object().shape({
  userId: yup.string().required(),
  subject: yup.string().max(50).required(),
  htmlBody: yup.string().required(),
})

export const EmailModal = ({ visible, user, hide, refetchHistory }) => {
  const { isLoading, enableLoading } = useLoading()

  const reactQuillRef = useRef(null)

  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
    setValue,
    watch,
    reset,
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
    defaultValues: { userId: user.id, subject: '', htmlBody: '' },
  })

  const hideAndClearModal = () => {
    // is needed to clear the reactQuill instance.
    // @see https://github.com/quilljs/quill/issues/1821#issuecomment-344210677
    reactQuillRef.current?.editor.setContents([{ insert: '\n' }])
    reset()
    hide()
  }

  const onSubmit = enableLoading(async formValues => {
    try {
      await request('/user/send-email', formValues)

      toast.success(`Successfully sent an email to ${user.username}`)
    } catch (error) {
      setError('subject', { type: 'required', message: error.message })
    }
    refetchHistory()
    hideAndClearModal()
  })

  return (
    <Modal visible={visible} onCancel={hideAndClearModal} footer={null} maskClosable={false}>
      <Form size='large' layout='vertical' autoComplete='off' onFinish={handleSubmit(onSubmit)}>
        <Form.Item
          name='userId'
          validateStatus={errors.userId?.message && 'error'}
          help={errors.userId?.message}>
          <Typography.Text>To: {user.email}</Typography.Text>
        </Form.Item>

        <Form.Item
          name='subject'
          validateStatus={errors.subject?.message && 'error'}
          help={errors.subject?.message}>
          <Controller
            control={control}
            name='subject'
            render={({ field }) => <Input placeholder='Subject' {...field} />}
          />
        </Form.Item>

        <Form.Item
          initialValue=''
          name='htmlBody'
          validateStatus={errors.htmlBody?.message && 'error'}
          help={errors.htmlBody?.message}>
          <Wysiwyg
            ref={reactQuillRef}
            placeholder='Body'
            value={watch('htmlBody')}
            onChange={value => setValue('htmlBody', value)}
          />
        </Form.Item>

        <Form.Item noStyle>
          <Flex justifyEnd>
            <Button
              type='default'
              htmlType='button'
              onClick={hideAndClearModal}
              style={{ marginRight: '16px' }}>
              Cancel
            </Button>

            <Button type='primary' htmlType='submit' loading={isLoading}>
              Send
            </Button>
          </Flex>
        </Form.Item>
      </Form>
    </Modal>
  )
}
