import React, { useState } from 'react'
import { Button, Typography } from 'antd'
import dayjs from 'dayjs'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { request } from '~/http'
import { AccessGuard, Flex } from '~/components'
import { userStatusConfigs } from '~/utils/user'
import { config } from '~/config'
import { useLoading } from '~/hooks/use-loading'
import { permissions } from '~/config/permissions'
import { Avatar } from '~/components/Avatar'
import { ProfileImageDeletionModal } from './ProfileImageDeletionModal'
import { EmailModal } from './EmailModal'
import { SuspendUserModal } from './SuspendUserModal'
import { UserDeletionModal } from './UserDeletionModal'
import { ProfileImageUploadModal } from './ProfileImageUploadModal'
import { enhanceUser } from '~/hooks/auth'
import { ResetUserModal } from './ResetUserModal'

const Table = styled.table`
  margin-left: 25px;
`

const Td = styled.td`
  padding: 5px 25px;
`

const LongTextWrapper = styled.div`
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const Overview = ({ refetchHistory, sessionUser, userId, user, refetchUser, refetchMe }) => {
  const navigate = useNavigate()

  const [profileImageUploadIsVisible, setProfileImageUploadIsVisible] = useState(false)
  const [profileImageDeletionModalIsVisible, setProfileImageDeletionModalIsVisible] = useState(false)
  const [userDeletionModalIsVisible, setUserDeletionModalIsVisible] = useState(false)
  const [emailModalIsVisible, setEmailModalIsVisible] = useState(false)
  const [suspendUserModalIsVisible, setSuspendUserModalIsVisible] = useState(false)
  const [ResetUserModalIsVisible, setResetUserModalIsVisible] = useState(false)

  const { isSuspendLoading, enableSuspendLoading } = useLoading('suspend')

  const statusConfig = userStatusConfigs[user.status]

  const isSuspended = user.status === 'suspended'

  const openSuspendUserModal = () => setSuspendUserModalIsVisible(true)

  const unSuspendUser = enableSuspendLoading(async () => {
    try {
      await request('/admin/suspend-user', { userId, unSuspending: true })

      refetchUser()
      refetchHistory()
      toast.success(`Successfully un-suspended ${user.username}`)
    } catch (e) {
      toast.error(`Could not un-suspended ${user.username}. Reason: ${e.message}`)
    }
  })

  const { url, isDefault } = enhanceUser(user).getAvatar()

  return (
    <>
      <div>
        <Typography.Title style={{ marginBottom: 50 }}>
          User Profile - {user.username || user.email}
        </Typography.Title>

        <Flex justifyStart noWrap>
          <Flex column alignCenter>
            <Avatar
              imageUrl={url}
              clickable={enhanceUser(user).isPermitted(permissions.user_write)}
              onClick={() => setProfileImageUploadIsVisible(true)}
            />

            <AccessGuard permissions={[permissions.user_write]}>
              <Button
                data-testid='profile-image-delete'
                style={{ marginTop: 15 }}
                danger
                onClick={() => setProfileImageDeletionModalIsVisible(true)}
                disabled={isDefault}>
                Delete Image
              </Button>
            </AccessGuard>
          </Flex>

          <Flex>
            <Table>
              <tbody>
                <tr>
                  <td />
                  <td />
                  <td>
                    <Button
                      disabled={sessionUser.isRestricted(permissions.user_write)}
                      type='default'
                      style={{ width: '100%', minWidth: '200px', backgroundColor: '#ffc107' }}
                      onClick={() => setResetUserModalIsVisible(true)}>
                      Reset User
                    </Button>
                  </td>
                </tr>
                <tr>
                  <Td colSpan={3}>
                    <Typography.Title level={4} style={{ color: statusConfig.color }}>
                      {statusConfig?.label}
                    </Typography.Title>
                  </Td>
                </tr>

                <tr>
                  <Td>
                    <LongTextWrapper>
                      <Typography.Text title={enhanceUser(user).getFullName()}>
                        Name: {enhanceUser(user).getFullName() || ''}
                      </Typography.Text>
                    </LongTextWrapper>
                  </Td>
                  <Td>
                    <Typography.Text data-testid='user-joined'>
                      <Flex noWrap justifyBetween style={{ whiteSpace: 'nowrap' }}>
                        <span>Joined:</span>
                        <span style={{ marginLeft: '20px' }}>
                          {dayjs(user.createdAt).format(config.DATE_FORMAT_FULL)}
                        </span>
                      </Flex>
                    </Typography.Text>
                  </Td>
                  <Td>
                    <Button
                      disabled={sessionUser.isRestricted(permissions.user_write)}
                      type='default'
                      style={{ width: '100%', minWidth: '200px' }}
                      loading={isSuspendLoading}
                      onClick={isSuspended ? unSuspendUser : openSuspendUserModal}>
                      {isSuspended ? 'Un-Suspend User' : 'Suspend User'}
                    </Button>
                  </Td>
                </tr>

                <tr>
                  <Td>
                    <LongTextWrapper>
                      <Typography.Link
                        title={user.email}
                        style={{ whiteSpace: 'nowrap' }}
                        disabled={sessionUser.isRestricted(permissions.user_write)}
                        href={`mailto:${user.email}`}
                        onClick={event => {
                          event.preventDefault()

                          setEmailModalIsVisible(true)
                        }}>
                        {user.email}
                      </Typography.Link>
                    </LongTextWrapper>
                  </Td>
                  <Td>
                    <Typography.Text data-testid='user-last-seen'>
                      <Flex noWrap justifyBetween style={{ whiteSpace: 'nowrap' }}>
                        <span>Last Seen:</span>
                        <span style={{ marginLeft: '20px' }}>
                          {dayjs(user.lastSeen).format(config.DATE_FORMAT_FULL)}
                        </span>
                      </Flex>
                    </Typography.Text>
                  </Td>
                  <Td>
                    <Button
                      disabled={sessionUser.isRestricted(permissions.user_write)}
                      type='danger'
                      style={{ width: '100%', minWidth: '200px' }}
                      onClick={() => setUserDeletionModalIsVisible(true)}>
                      Delete User
                    </Button>
                  </Td>
                </tr>
                <tr>
                  <Td>
                    <Typography.Text data-testid='user-role'>
                      <Flex noWrap justifyBetween style={{ whiteSpace: 'nowrap' }}>
                        <span>Role:</span>
                        <span style={{ marginLeft: '20px' }}>{enhanceUser(user).getRole()}</span>
                      </Flex>
                    </Typography.Text>
                  </Td>
                </tr>
                <tr>
                  <Td>
                    <Typography.Text data-testid='user-role'>
                      <Flex noWrap justifyBetween style={{ whiteSpace: 'nowrap' }}>
                        <span>Career Path:</span>
                        <span>{user.careerPath}</span>
                      </Flex>
                    </Typography.Text>
                  </Td>
                </tr>
              </tbody>
            </Table>
          </Flex>
        </Flex>
      </div>

      <ProfileImageUploadModal
        user={user}
        visible={profileImageUploadIsVisible}
        hide={() => setProfileImageUploadIsVisible(false)}
        onSuccess={() => {
          refetchUser()
          refetchHistory()
          refetchMe()
        }}
      />

      <ProfileImageDeletionModal
        user={user}
        visible={profileImageDeletionModalIsVisible}
        hide={() => setProfileImageDeletionModalIsVisible(false)}
        onSuccess={() => {
          refetchUser()
          refetchHistory()
          refetchMe()
        }}
      />

      <UserDeletionModal
        user={user}
        visible={userDeletionModalIsVisible}
        hide={() => setUserDeletionModalIsVisible(false)}
        onSuccess={() => {
          navigate('/users/list')
          refetchHistory()
          refetchUser()
        }}
      />

      <EmailModal
        user={user}
        visible={emailModalIsVisible}
        hide={() => setEmailModalIsVisible(false)}
        refetchHistory={refetchHistory}
      />

      <SuspendUserModal
        user={user}
        visible={suspendUserModalIsVisible}
        hide={() => setSuspendUserModalIsVisible(false)}
        onSuccess={() => {
          refetchUser()
          refetchHistory()
        }}
      />

      <ResetUserModal
        user={user}
        visible={ResetUserModalIsVisible}
        hide={() => setResetUserModalIsVisible(false)}
        onSuccess={() => {
          refetchUser()
          refetchHistory()
          refetchMe()
        }}
      />
    </>
  )
}
