import React, { useState } from 'react'
import { Modal, Typography, Input, Button } from 'antd'
import { useLoading } from '~/hooks/use-loading'
import { toast } from 'react-toastify'
import { request } from '~/http'
import { Flex } from '~/components'
import { useParams } from 'react-router'

export const AddAdminToClientModal = ({ open, setOpen, fetchDetails }) => {
  const { isLoading, enableLoading } = useLoading()
  const { company } = useParams()
  const [adminEmail, setAdminEmail] = useState('')

  const handleAddNewClient = enableLoading(async () => {
    try {
      await request('/clients/add-admin-user', {
        email: adminEmail,
        company,
      })

      await fetchDetails()

      handleCancel()
    } catch (e) {
      toast.error(`Error addinge new admin: ${e.message || e}`)
    }
  })

  const handleCancel = () => {
    setAdminEmail('')
    setOpen(false)
  }

  const isSubmitDisabled = isLoading || adminEmail === ''

  return (
    <Modal
      open={open}
      onCancel={handleCancel}
      onOk={handleAddNewClient}
      footer={[
        <Button key='cancel' onClick={handleCancel}>
          Cancel
        </Button>,
        <Button key='add' disabled={isSubmitDisabled} onClick={handleAddNewClient}>
          Add Admin
        </Button>,
      ]}>
      <Typography.Title>Add Admin</Typography.Title>

      <Flex flexDirection='row' gap='24px'>
        <Typography.Text>Add admin email</Typography.Text>
        <Input
          autoSize={{ minRows: 6, maxRows: 6 }}
          value={adminEmail}
          onChange={e => setAdminEmail(e.target.value)}
        />
      </Flex>
    </Modal>
  )
}
