import React, { useMemo } from 'react'
import { Layout, Table, Tag, Typography } from 'antd'
import dayjs from 'dayjs'
import styled from 'styled-components'
import * as _ from 'lodash'
import parser from 'ua-parser-js'
import ReactJson from 'react-json-view'

import { useBreadcrumb } from '~/hooks/breadcrumbs'
import { useUser } from '~/hooks/auth'
import { permissions } from '~/config/permissions'
import { Flex, UserSearch } from '~/components'
import { useParams } from 'react-router'
import { config } from '~/config'
import { useDataTable } from '~/hooks/use-data-table'
import { useRequest } from '~/hooks/use-request'

const NonWrappingText = styled.div`
  white-space: nowrap;
`

const getColumns = () => [
  {
    title: 'Date/Time',
    dataIndex: 'createdAt',
    render: value => (
      <NonWrappingText>{value ? dayjs(value).format(config.DATE_FORMAT_FULL) : null}</NonWrappingText>
    ),
  },
  {
    title: 'Name',
    dataIndex: 'user.name',
    render: value => <NonWrappingText>{value}</NonWrappingText>,
  },
  {
    title: 'Username',
    dataIndex: 'user.username',
    sorter: true,
    render: value => <NonWrappingText>{value}</NonWrappingText>,
  },
  {
    title: 'Email',
    dataIndex: 'user.email',
    sorter: true,
    render: value => <NonWrappingText>{value}</NonWrappingText>,
  },
  {
    title: 'Device',
    dataIndex: 'device',
  },
  {
    title: 'Data',
    dataIndex: 'data',
  },
  {
    title: 'First?',
    dataIndex: 'isFirst',
    render: isFirst => (
      <Tag style={{ margin: '4px' }} color={isFirst ? 'green' : 'red'}>
        {isFirst ? 'Yes' : 'No'}
      </Tag>
    ),
  },
]

const DEFAULT_REQUEST_ARGUMENTS = Object.freeze({
  query: '',
  limit: 50,
  skip: 0,
  current: 1,
  sort: { createdAt: 'desc' },
})

export const EventDetails = () => {
  const { user } = useUser()
  const { eventType } = useParams()

  const { data, isLoading, pagination, handleSearch, handleChange } = useDataTable({
    path: '/event/list',
    defaultRequestArguments: { ...DEFAULT_REQUEST_ARGUMENTS, types: [eventType] },
    onSort: sort => ({ ...sort, createdAt: 'desc' }),
  })

  const { data: eventTypesData } = useRequest('/gamification/event-types')

  const eventNameHumanReadable = useMemo(() => {
    const result = eventTypesData?.eventTypes.find(({ eventType: type }) => type === eventType)

    return `"${_.startCase(_.toLower(result?.name)) || eventType}"`
  }, [eventTypesData, eventType])

  useBreadcrumb(2, { label: 'Events', path: '/gamification/list' })
  useBreadcrumb(3, {
    label: `Events - ${eventNameHumanReadable}`,
    path: `/gamification/event-details/${eventType}`,
  })

  const isAllowed =
    user.isPermitted(permissions.gamification_read) || user.isPermitted(permissions.gamification_write)

  const dataSource = data?.events.map(({ id, createdAt, isFirst, data, user: { email, username } }) => {
    const { browser, os } = parser(data?.deviceType)
    return {
      key: `${id}-${createdAt}`,
      createdAt,
      'user.username': username,
      'user.email': email,
      device: data?.deviceType || `${browser.name} (${browser.version}) / ${os.name} (${os.version})`,
      data: <ReactJson src={data} collapsed={true} name='data' />,
      isFirst,
    }
  })

  return (
    <Layout.Content>
      <Typography.Title>Event: Events - {eventNameHumanReadable}</Typography.Title>

      <UserSearch onSubmit={handleSearch} isLoading={isLoading} />

      {isAllowed ? (
        <Table
          rowKey='key'
          data-testid='event-details-table'
          style={{ width: '100%' }}
          dataSource={dataSource}
          pagination={pagination}
          sortDirections={['ascend', 'descend']}
          onChange={handleChange}
          columns={getColumns()}
          loading={isLoading}
        />
      ) : (
        <Flex justifyCenter style={{ width: '80%' }}>
          <Typography.Text>Missing permissions to access this module</Typography.Text>
        </Flex>
      )}
    </Layout.Content>
  )
}
