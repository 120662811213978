import React, { useRef } from 'react'
import { Button, Form, Modal, Select, Typography, Input } from 'antd'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { request } from '~/http'
import { Flex, Wysiwyg } from '~/components'
import { useLoading } from '~/hooks/use-loading'

const schema = yup.object().shape({
  reason: yup.string().required(),
  message: yup
    .string()
    .test('required', 'message field is required', value => value && value !== '<p><br></p>'),
  adminNotes: yup.string().required(),
})

export const SuspendUserModal = ({ visible, user, hide, onSuccess }) => {
  const { isLoading, enableLoading } = useLoading()

  const reactQuillRef = useRef()

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
    reset,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: { userId: user.id, reason: undefined, message: '', adminNotes: '' },
  })

  const hideAndClearModal = () => {
    // is needed to clear the reactQuill instance.
    // @see https://github.com/quilljs/quill/issues/1821#issuecomment-344210677
    reactQuillRef.current?.editor.setContents([{ insert: '\n' }])
    reset()
    hide()
  }

  const onSubmit = enableLoading(async formValues => {
    try {
      await request('/admin/suspend-user', formValues)

      onSuccess()

      toast.success(`User ${user.username} successfully suspended`)
    } catch (e) {
      toast.error(`User ${user.username} could not be suspended. Reason ${e.message}`)
    }

    hideAndClearModal()
  })

  return (
    <Modal visible={visible} onCancel={hideAndClearModal} footer={null} maskClosable={false}>
      <Form size='large' layout='vertical' autoComplete='off' onFinish={handleSubmit(onSubmit)}>
        <Typography className='Text' style={{ marginBottom: '24px', fontSize: '18px' }}>
          User will no longer be able to take courses or earn rewards, are you sure you want to suspend{' '}
          <strong>{user.username}</strong>?
        </Typography>

        <Form.Item
          name='reason'
          validateStatus={errors.reason?.message && 'error'}
          help={errors.reason?.message}>
          <Controller
            control={control}
            name='reason'
            render={({ field }) => (
              <Select size='default' placeholder='Choose a reason' style={{ width: 200 }} {...field}>
                {[
                  { label: 'Abuse', value: 'abuse' },
                  { label: 'Harassment', value: 'harassment' },
                  { label: 'Inappropriate Conduct', value: 'inappropriate_conduct' },
                  { label: 'Other', value: 'other' },
                ].map(({ label, value }) => (
                  <Select.Option key={value} value={value}>
                    {label}
                  </Select.Option>
                ))}
              </Select>
            )}
          />
        </Form.Item>

        <Form.Item
          initialValue=''
          name='message'
          validateStatus={errors.message?.message && 'error'}
          help={errors.message?.message}>
          <Controller
            control={control}
            name='message'
            render={({ field }) => (
              <Wysiwyg
                ref={reactQuillRef}
                placeholder='Message to the user'
                hasError={!!errors.message?.message}
                {...field}
              />
            )}
          />
        </Form.Item>

        <Form.Item
          name='adminNotes'
          validateStatus={errors.adminNotes?.message && 'error'}
          help={errors.adminNotes?.message}>
          <Controller
            control={control}
            name='adminNotes'
            render={({ field }) => (
              <Input.TextArea size='default' placeholder='Admin notes' {...field} />
            )}
          />
        </Form.Item>

        <Form.Item noStyle>
          <Flex justifyEnd>
            <Button
              type='default'
              htmlType='button'
              onClick={hideAndClearModal}
              style={{ marginRight: '16px' }}>
              Cancel
            </Button>

            <Button type='danger' htmlType='submit' loading={isLoading} disabled={!isValid}>
              Suspend
            </Button>
          </Flex>
        </Form.Item>
      </Form>
    </Modal>
  )
}
