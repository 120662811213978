import { commonConfig } from './common'
import { permissions } from './permissions'
import { userStatuses } from './userStatuses'
import { courseraConfig } from './coursera'
import { authConfig as auth } from './auth'

export const config = {
  ...commonConfig,
  permissions,
  userStatuses,
  auth,
  courseraConfig,
}
