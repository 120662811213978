import React from 'react'
import PropTypes from 'prop-types'
import { useUser } from '~/hooks/auth'

export const AccessGuard = ({
  children,
  permissions = [],
  fallbackElement = <></>,
  loadingElement = <>Loading..</>,
}) => {
  const { user, isLoading } = useUser()

  if (isLoading) return loadingElement

  return user.isPermitted(permissions) ? children : fallbackElement
}

AccessGuard.propTypes = {
  children: PropTypes.node.isRequired,
  permissions: PropTypes.arrayOf(PropTypes.string),
  fallbackElement: PropTypes.node,
  loadingElement: PropTypes.node,
}
