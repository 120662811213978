import React, { useState } from 'react'
import { Modal, Typography, Input, Button, DatePicker } from 'antd'
import { useLoading } from '~/hooks/use-loading'
import { toast } from 'react-toastify'
import { request } from '~/http'
import { Flex } from '~/components'
import dayjs from 'dayjs'

export const AddNewB2BClientModal = ({ open, setOpen, freeAccessUserList }) => {
  const { isLoading, enableLoading } = useLoading()

  const [clientCompanyName, setClientCompanyName] = useState('')
  const [licensesQty, setLicensesQty] = useState(null)
  const [company, setCompany] = useState('')
  const [contractStartDate, setContractStartDate] = useState(null)
  const [contractEndDate, setContractEndDate] = useState(null)

  const handleAddNewClient = enableLoading(async () => {
    try {
      await request('/clients/create', {
        clientCompanyName,
        licensesQty,
        company,
        contractStartDate,
        contractEndDate,
      })

      handleCancel()

      return
    } catch (e) {
      toast.error(`Error adding new B2B client: ${e.message || e}`)
    }
  })

  const handleCancel = () => {
    setClientCompanyName('')
    setLicensesQty(null)
    setCompany('')
    setContractStartDate(null)
    setContractEndDate(null)
    setOpen(false)
  }

  const isSubmitDisabled =
    isLoading ||
    clientCompanyName === '' ||
    licensesQty === null ||
    company === '' ||
    contractStartDate === null ||
    contractEndDate === null

  const disabledDate = current => {
    return current < dayjs(contractStartDate).endOf('day')
  }

  return (
    <Modal
      open={open}
      onCancel={handleCancel}
      onOk={handleAddNewClient}
      footer={[
        <Button key='cancel' onClick={handleCancel}>
          Cancel
        </Button>,
        <Button key='add' disabled={isSubmitDisabled} onClick={handleAddNewClient}>
          Create Client
        </Button>,
      ]}>
      <Typography.Title>New Client</Typography.Title>

      <Flex flexDirection='row' gap='24px'>
        <Input
          placeholder='Client Company Name'
          value={clientCompanyName}
          onChange={e => setClientCompanyName(e.target.value)}
        />
        <Input
          placeholder='Licenses'
          type='number'
          value={licensesQty}
          onChange={e => setLicensesQty(e.target.value)}
        />
        <Input
          placeholder='Company Parameter (lower case only, no spaces)'
          value={company}
          onChange={e => setCompany(e.target.value)}
        />
        <DatePicker
          placeholder='Contract Start'
          dateRender={current => {
            return <div className='ant-picker-cell-inner'>{current.date()}</div>
          }}
          value={contractStartDate}
          onChange={date => setContractStartDate(date)}
          style={{ width: '100%' }}
        />
        <DatePicker
          placeholder='Contract End'
          dateRender={current => {
            return <div className='ant-picker-cell-inner'>{current.date()}</div>
          }}
          value={contractEndDate}
          onChange={date => setContractEndDate(date)}
          style={{ width: '100%' }}
          disabledDate={disabledDate}
        />
      </Flex>
    </Modal>
  )
}
