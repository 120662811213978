/* istanbul ignore file */
import React from 'react'
import styled, { css } from 'styled-components'

const alignStyle = css`
  text-align: ${props => {
    if (props.center) return 'center'
    if (props.right) return 'right'
    return 'left'
  }};
  @media (max-width: 1000px) {
    ${props =>
      props.mediumRight &&
      css`
        text-align: right;
      `}
    ${props =>
      props.mediumCenter &&
      css`
        text-align: center;
      `}
    ${props =>
      props.mediumLeft &&
      css`
        text-align: left;
      `}
  }

  @media (max-width: 750px) {
    ${props =>
      props.smallRight &&
      css`
        text-align: right;
      `}
    ${props =>
      props.smallCenter &&
      css`
        text-align: center;
      `}
    ${props =>
      props.smallLeft &&
      css`
        text-align: center;
      `}
  }
`

const baseStyles = css`
  ${alignStyle}
  max-width: 100%;
  margin-top: 0;

  font-size: ${props => {
    if (props.small) return '0.75rem'
    if (props.big) return '1.25rem'
    if (props.size) return `${props.size}px`
    return '1rem'
  }};

  opacity: ${props => (props.muted ? 0.35 : 1)};

  color: ${props => {
    if (props.color) return props.color
    if (props.error) return '#cc0000'
    if (props.light) return '#fff'
    if (props.warn) return '#cf6f34'
    return '#000'
  }};

  font-weight: ${props => {
    if (props.bold) return 700
    if (props.medium) return 500
    if (props.thin) return 300
    return 400
  }};

  width: ${props => {
    if (props.width) return props.width
    if (props.inline) return null
    return '100%'
  }};

  margin-bottom: ${props => {
    if (props.margin) return '10px'
    return 0
  }};

  font-family: 'Work Sans';
`

const StyledParagraph = styled.p`
  ${baseStyles};
`

const StyledSpan = styled.span`
  ${baseStyles};
`

export const Text = ({ inline, strong, ...props }) => {
  if (strong) props.bold = true
  if (inline) return <StyledSpan inline={inline} {...props} />
  return <StyledParagraph {...props} />
}
