import _ from 'lodash'
import merge from 'lodash/merge'

export const request = async (path, data, options) => {
  if (_.isNil(data)) data = {}
  const accessToken = localStorage.getItem('accessToken')

  const defaultOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {}),
    },
    body: JSON.stringify(data),
  }

  // trim slashes from the beginning of the string
  path = path.replace(/^\/+/, '')

  const response = await fetch(
    `${process.env.SERVER_URL}/service/${path}`,
    merge(defaultOptions, options)
  )

  const responseData = await response.json()

  if (response.status >= 300) throw new Error(responseData.message)

  return responseData
}
