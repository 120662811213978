import { Typography } from 'antd'
import React from 'react'
import { Routes, Route, Navigate } from 'react-router'
import { Sidebar, Flex, Container, Loader } from '~/components'
import { NotFound } from '~/screens'
import { permissions } from '~/config/permissions'
import { useUser } from '~/hooks/auth'
import { useBreadcrumb } from '~/hooks/breadcrumbs'
import { ClientList } from './list'
import { ClientDetails } from './details'

const sidebarLinks = [
  {
    path: '/list',
    label: 'Client List',
  },
]

export const B2BClientsModule = () => {
  useBreadcrumb(1, { label: 'B2B', path: '/b2b-clients' })

  const { user, isLoading } = useUser()

  if (isLoading) return <Loader placeholder />

  if (user.isRestricted(permissions.client_read)) {
    return (
      <Flex justifyCenter>
        <Typography.Text>Missing permissions to access this module</Typography.Text>
      </Flex>
    )
  }

  return (
    <Container>
      <Flex noWrap>
        <Flex column>
          <Sidebar items={sidebarLinks} root='/b2b-clients' />
        </Flex>

        <Routes>
          <Route index element={<Navigate to='./list' />} />
          <Route path='list' element={<ClientList />} />
          <Route path='details/:company' element={<ClientDetails />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
      </Flex>
    </Container>
  )
}
