import { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body,
  html {
    margin: 0;
    height: 100%;
    font-family: 'Work Sans', sans-serif;
    color: #202020;
    font-size: 16px;
    background: #f0f2f5;
  }

  html {
    overflow: auto;
  }

  h1, h2, h3, h4, h5, p {
    margin-top: 0;
  }
`
