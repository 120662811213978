import { Typography } from 'antd'
import React from 'react'
import { Layout } from '~/Application/Layout/Layout'
import { Container } from '~/components/Container'

export const NotFound = ({ includeLayout }) => {
  const Wrapper = includeLayout
    ? ({ children }) => {
        return (
          <Layout>
            <Container>{children}</Container>
          </Layout>
        )
      }
    : React.Fragment

  return (
    <Wrapper>
      <Typography.Text style={{ fontSize: 36 }}>Page Not Found...</Typography.Text>
    </Wrapper>
  )
}
