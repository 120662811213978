export const permissions = Object.freeze({
  admin_write: 'admin_write',
  admin_read: 'admin_read',
  user_read: 'user_read',
  user_write: 'user_write',
  client_read: 'client_read',
  client_write: 'client_write',
  gamification_read: 'gamification_read',
  gamification_write: 'gamification_write',
})
