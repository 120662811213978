import { useParams } from 'react-router'
import React from 'react'
import { Button, Typography } from 'antd'
import { Flex, Loader } from '~/components'
import { useBreadcrumb } from '~/hooks/breadcrumbs'
import { useRequest } from '~/hooks/use-request'
import { ClientOverview } from './ClientOverview'
import { ClientContactDetails } from './ClientContactDetails'
import { ClientUsers } from './ClientUsers'

export const ClientDetails = () => {
  const { company } = useParams()

  useBreadcrumb(2, { label: 'Client List', path: '/b2b-clients/list' })
  useBreadcrumb(3, { label: 'Details', path: `/b2b-clients/details/${company}` })

  const {
    data,
    isValidating,
    mutate: refetchCompany,
    error,
  } = useRequest(
    '/clients/details',
    { company },
    {
      swrOptions: { revalidateOnFocus: false },
    }
  )

  const loading = !data && isValidating

  if (loading) return <Loader placeholder />

  if (error) {
    return (
      <Flex>
        <Typography.Text type='danger'>{error.message}</Typography.Text>
        <Button onClick={refetchCompany}>Retry</Button>
      </Flex>
    )
  }

  return (
    <Flex style={{ width: '100%' }}>
      <Typography.Title>{data.client.clientCompanyName}</Typography.Title>
      <div style={{ display: 'flex', width: '100%', gap: '12px' }}>
        <ClientOverview overview={data.client} refetchCompany={refetchCompany} />
        <ClientContactDetails contact={data.client.contact} refetchCompany={refetchCompany} />
      </div>

      <ClientUsers users={data.client.users} isLoading={loading} refetchCompany={refetchCompany} />
    </Flex>
  )
}
