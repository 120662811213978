import React, { useState } from 'react'
import { Modal, Typography, Input, Button, Checkbox } from 'antd'
import { useLoading } from '~/hooks/use-loading'
import { toast } from 'react-toastify'
import { request } from '~/http'
import { Flex } from '~/components'
import { useParams } from 'react-router'

export const AddUsersToClientModal = ({ open, setOpen, fetchDetails }) => {
  const { isLoading, enableLoading } = useLoading()
  const { company } = useParams()
  const [usersEmails, setUsersEmails] = useState('')
  const [sendInvitations, setSendInvitations] = useState(false)

  const handleAddNewClient = enableLoading(async () => {
    try {
      const emails = usersEmails
        .trim()
        .split(/\r?\n/)
        .filter(email => email.length > 0)

      if (emails.length > 0) {
        const result = await request('/clients/add-users', {
          emails: [...emails],
          sendInvitations,
          company,
        })

        if (result.errorUserList.length > 0) {
          result.errorUserList.forEach(error => {
            toast.error(`Error adding user. ${error.email}: ${error.message}`)
          })
        } else {
          toast.success(`Successfully added users to ${company}`)
        }

        await fetchDetails()

        handleCancel()
      }
    } catch (e) {
      toast.error(`Error adding new B2B client: ${e.message || e}`)
    }
  })

  const handleCancel = () => {
    setUsersEmails('')
    setSendInvitations(false)
    setOpen(false)
  }

  const isSubmitDisabled = isLoading || usersEmails === ''

  return (
    <Modal
      open={open}
      onCancel={handleCancel}
      onOk={handleAddNewClient}
      footer={[
        <Checkbox
          key='add-invitation'
          style={{ marginRight: '80px' }}
          checked={sendInvitations}
          onChange={e => setSendInvitations(e.target.checked)}>
          Send Invitations to users?
        </Checkbox>,
        <Button key='cancel' onClick={handleCancel}>
          Cancel
        </Button>,
        <Button key='add' disabled={isSubmitDisabled} onClick={handleAddNewClient}>
          Add Users
        </Button>,
      ]}>
      <Typography.Title>Add Users</Typography.Title>

      <Flex flexDirection='row' gap='24px'>
        <Typography.Text>Add one email address per line</Typography.Text>
        <Input.TextArea
          autoSize={{ minRows: 6, maxRows: 6 }}
          value={usersEmails}
          onChange={e => setUsersEmails(e.target.value)}
        />
      </Flex>
    </Modal>
  )
}
