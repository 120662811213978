import React, { useState } from 'react'
import { Modal, Typography, Button, Input, DatePicker } from 'antd'
import { useLoading } from '~/hooks/use-loading'
import { toast } from 'react-toastify'
import { request } from '~/http'
import { Flex } from '~/components'
import { useParams } from 'react-router'
import dayjs from 'dayjs'

export const EditClientModal = ({ open, setOpen, overview, refetchCompany }) => {
  const { isLoading, enableLoading } = useLoading()
  const { company } = useParams()

  const [licensesQty, setLicensesQty] = useState(overview?.licensesQty)
  const [contractEndDate, setContractEndDate] = useState(dayjs(overview?.contractEndDate))

  const handleCancel = () => {
    setLicensesQty(overview?.licensesQty)
    setContractEndDate(dayjs(overview?.contractEndDate))
    setOpen(false)
  }

  const handleAddContact = enableLoading(async () => {
    try {
      await request('/clients/edit', {
        company,
        licensesQty,
        contractEndDate,
      })

      await refetchCompany()
      handleCancel()
    } catch (e) {
      toast.error(`Error editing client: ${e.message || e}`)
    }
  })

  const disabledDate = current => {
    return current < dayjs(overview?.contractStartDate).endOf('day')
  }

  return (
    <Modal
      open={open}
      onCancel={handleCancel}
      onOk={handleAddContact}
      footer={[
        <Button key='cancel' onClick={handleCancel}>
          Cancel
        </Button>,
        <Button key='add' disabled={isLoading} onClick={handleAddContact}>
          Save changes
        </Button>,
      ]}>
      <Typography.Title>Edit Client</Typography.Title>

      <Flex flexDirection='row' gap='24px'>
        <Input placeholder='Client Name' value={overview?.clientCompanyName} disabled={true} />
        <Input placeholder='Company Parameter' value={overview?.company} disabled={true} />
        <Input
          placeholder='Licenses'
          value={licensesQty}
          onChange={e => setLicensesQty(e.target.value)}
          type='number'
        />
        <DatePicker
          placeholder='Contract Start'
          style={{ width: '100%' }}
          value={dayjs(overview?.contractStartDate)}
          disabled
        />
        <DatePicker
          placeholder='Contract End'
          style={{ width: '100%' }}
          value={contractEndDate}
          onChange={date => setContractEndDate(date)}
          disabledDate={disabledDate}
        />
      </Flex>
    </Modal>
  )
}
