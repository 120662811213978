import React, { useState } from 'react'
import { Typography, Button } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import { EditClientModal } from './EditClientModal'
import { useUser } from '~/hooks/auth'

export const ClientOverview = ({ overview, refetchCompany }) => {
  const [open, setOpen] = useState(false)
  const { user } = useUser()

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid black',
        borderRadius: '8px',
        width: '100%',
        gap: '8px',
        padding: '24px',
      }}>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
        <Typography.Text>Client Details</Typography.Text>
        <Button
          shape='circle'
          icon={<EditOutlined />}
          onClick={() => setOpen(true)}
          disabled={!user.permissions.some(p => p === 'client_write')}
        />
      </div>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
        <Typography.Text>Seats remaining</Typography.Text>
        <Typography.Text>{`${overview.usersQty}/${overview.licensesQty}`}</Typography.Text>
      </div>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
        <Typography.Text>Contract Expiration</Typography.Text>
        <Typography.Text>{dayjs(overview.contractEndDate).format('MM/DD/YYYY')}</Typography.Text>
      </div>
      <EditClientModal
        open={open}
        setOpen={setOpen}
        refetchCompany={refetchCompany}
        overview={overview}
      />
    </div>
  )
}
