import { useAuth0 } from '@auth0/auth0-react'
import difference from 'lodash/difference'
import isEmpty from 'lodash/isEmpty'
import { useEffect } from 'react'

import defaultAvatar from '~/assets/images/astronaut_avatar.png'
import { useRequest } from '../use-request'

export const enhanceUser = user => {
  user ??= {}

  const thumbUrl = user.profileImage?.thumbUrl

  const isPermitted = requestedPermissions => {
    if (!requestedPermissions) return true

    requestedPermissions = Array.isArray(requestedPermissions)
      ? requestedPermissions
      : [requestedPermissions]

    return difference(requestedPermissions, user.permissions).length === 0
  }

  const getRole = () => {
    if (user.permissions.length > 0) return 'Admin'
    return 'User'
  }

  return {
    isEmpty: () => isEmpty(user),
    isPermitted,
    getRole,
    isRestricted: requestedPermissions => !isPermitted(requestedPermissions),
    getAvatar: () => ({
      url: thumbUrl || defaultAvatar,
      isDefault: !thumbUrl,
    }),
    getFullName: () => `${user?.firstName || ''} ${user?.lastName || ''}`.trim(),
    ...user,
  }
}

// inclusion of the `accessToken` here let's us leverage it as a cache buster
export const useUser = () => {
  const { logout } = useAuth0()
  const accessToken = localStorage.getItem('accessToken')

  const { data, error, isLoading, mutate } = useRequest('/user/me', null, {
    options: {
      headers: { Authorization: `Bearer ${accessToken}` },
    },
    swrOptions: { revalidateOnFocus: false },
  })

  useEffect(() => {
    const user = data
    let timeout
    if (user && !user.permissions.includes('admin_read') && !user.permissions.includes('admin_write')) {
      timeout = setTimeout(() => {
        logout({ returnTo: window.location.origin })
      }, 5000)
    }

    return () => clearTimeout(timeout)
  }, [data, logout])

  return {
    isLoading,
    user: enhanceUser(error ? null : data),
    error,
    mutate,
  }
}
