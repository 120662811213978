import React from 'react'

import { Flex } from '~/components'
import { Typography } from 'antd'
import { UserEventsTable } from './UserEventsTable'
import { useDataTable } from '~/hooks/use-data-table'

const DEFAULT_REQUEST_ARGUMENTS = Object.freeze({
  query: '',
  limit: 10,
  skip: 0,
  current: 1,
  sort: { createdAt: 'desc' },
})

export const UserEvents = ({ userId }) => {
  const {
    data,
    isValidating,
    pagination,
    mutate: refetch,
    error,
    handleChange,
  } = useDataTable({
    path: '/event/list',
    defaultRequestArguments: { ...DEFAULT_REQUEST_ARGUMENTS, userIds: [userId] },
  })

  const loading = !data && isValidating

  const events = getUserEventsTableData(data)

  return (
    <Flex column style={{ marginTop: 50, width: '100%' }}>
      <Typography.Title>User Events</Typography.Title>
      <UserEventsTable
        events={events}
        isLoading={loading}
        pagination={{ ...pagination, showSizeChanger: false }}
        error={error}
        refetch={refetch}
        handleChange={handleChange}
      />
    </Flex>
  )
}

const getUserEventsTableData = data => {
  if (!data?.events) return []

  const { events } = data

  return events.map(event => ({
    key: event._id,
    name: event.type,
    date: event.createdAt,
    metadata: event.data,
  }))
}
