import { useState } from 'react'
import lowerCase from 'lodash/lowerCase'
import capitalize from 'lodash/capitalize'

export const useLoading = (key = '') => {
  const [isLoading, setIsLoading] = useState(false)

  const capitalizedKey = capitalize(lowerCase(key))

  return {
    [`is${capitalizedKey}Loading`]: isLoading,
    [`enable${capitalizedKey}Loading`]:
      callback =>
      async (...args) => {
        setIsLoading(true)

        // eslint-disable-next-line node/no-callback-literal
        await callback(...args)

        setIsLoading(false)
      },
  }
}
