import { useMemo } from 'react'
import useSWR, { mutate } from 'swr'
import { request } from '~/http'

const cache = {}

export const mutateByPath = path => mutate(cache[path])

export const getRequestKey = (path, payload) => (path ? JSON.stringify({ path, ...payload }) : null)

export const useRequest = (path, payload, { options, swrOptions } = {}) => {
  const key = useMemo(() => getRequestKey(path, payload), [path, payload])

  // save the `path: swrKey` relation to later use it to mutate the cache.
  // Because we're generating swr keys by merging cache and the payload, simply
  // calling `mutate(path)` won't work, hence the need for this workaround
  if (key) cache[path] = key

  const result = useSWR(key, () => request(path, payload, options), swrOptions)

  return { ...result, isLoading: result.isValidating && !result.data }
}
