import React from 'react'
import SpinnerSvg from './spinner.svg'

export const Spinner = ({ size, maxSize = 64 }) => (
  <img
    style={{
      height: size ? `${size}px` : '100%',
      width: size ? `${size}px` : '100%',
      objectFit: 'contain',
      maxWidth: `${maxSize}px`,
      maxHeight: `${maxSize}px`,
    }}
    src={SpinnerSvg}
    alt='Spinner'
  />
)
