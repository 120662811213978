import { useState } from 'react'
import _ from 'lodash'
import { useRequest } from '~/hooks/use-request'

export const useDataTable = ({ path, defaultRequestArguments, onSort = sortObject => sortObject }) => {
  const [requestArguments, setRequestArguments] = useState(defaultRequestArguments)
  const [currentSort, setCurrentSort] = useState()
  const [currentFilters, setCurrentFilters] = useState()

  const result = useRequest(path, requestArguments)

  const pagination = {
    current: requestArguments.current,
    total: result.data?.totalCount,
    pageSize: requestArguments.limit,
    position: ['bottomLeft'],
  }

  const handleSearch = ({ searchQuery }) =>
    setRequestArguments(prev => ({
      ...prev,
      query: searchQuery,
      current: prev.query === searchQuery ? prev.current : 1,
      skip: prev.query === searchQuery ? prev.skip : 0,
    }))

  const setSortArguments = arg => {
    let sort = null
    if (arg.order === 'ascend') {
      sort = { [arg.field]: 'asc' }
    } else if (arg.order === 'descend') {
      sort = { [arg.field]: 'desc' }
    }

    const finalSortObject = onSort(sort)

    setRequestArguments(prev => ({ ...prev, skip: 0, sort: finalSortObject }))
  }

  const handleChange = ({ current, pageSize }, filters, sort) => {
    let passedNumber = current
    if (!_.isEqual(currentSort, sort) && !_.isEmpty(sort)) {
      setCurrentSort(sort)
      setSortArguments(sort)
      passedNumber = 1
    }

    if (!_.isEqual(currentFilters, filters) && !_.isEmpty(filters)) {
      setCurrentFilters(filters)
      setRequestArguments(prev => {
        return { ...prev, skip: 0, statuses: filters.status }
      })
    }

    setRequestArguments(prev => ({
      ...prev,
      skip: Math.max((passedNumber - 1) * prev.limit),
      current: passedNumber,
      statuses: filters.status,
      companies: filters.company,
      limit: pageSize,
    }))
  }

  return {
    ...result,
    pagination,
    requestArguments,
    setRequestArguments,
    handleSearch,
    handleChange,
  }
}
