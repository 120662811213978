import React from 'react'
import { Sidebar } from '~/components'
import { Routes, Route, Navigate } from 'react-router'
import { Container } from '~/components/Container'
import { Flex } from '~/components/Flex'
import { Dashboard } from './dashboard'
import { CourseraLicenses } from './coursera-licenses'

const sidebarLinks = [
  {
    label: 'Coursera',
    path: '/coursera',
  },
]

export const PartnersModule = () => {
  return (
    <Container>
      <Flex noWrap>
        <Sidebar items={sidebarLinks} root='/partners' />

        <Routes>
          <Route index element={<Navigate to='./dashboard' />} />
          <Route path='dashboard' element={<Dashboard />} />
          <Route path='coursera' element={<CourseraLicenses />} />
        </Routes>
      </Flex>
    </Container>
  )
}
