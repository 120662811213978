import React, { forwardRef, useState } from 'react'
import { Upload } from 'antd'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'

const getBase64 = (img, callback) => {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}

export const UploadImage = forwardRef(({ label = 'Choose', onChange, isLoading, ...other }, ref) => {
  const [imageUrl, setImageUrl] = useState()

  return (
    <Upload
      ref={ref}
      {...other}
      listType='picture-card'
      customRequest={({ onSuccess }) => onSuccess()}
      showUploadList={false}
      onChange={({ file }) => {
        if (file.status === 'done') return

        onChange(file.originFileObj)
        getBase64(file.originFileObj, imgUrl => setImageUrl(imgUrl))
      }}>
      {imageUrl ? (
        <img
          src={imageUrl}
          alt='uploaded image'
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
        />
      ) : (
        <div>
          {isLoading ? <LoadingOutlined /> : <PlusOutlined />}

          <div style={{ marginTop: 8 }}>{label}</div>
        </div>
      )}
    </Upload>
  )
})
