import { userStatuses } from '~/config/userStatuses'

export const userStatusConfigs = {
  [userStatuses.registered]: {
    label: 'Registered',
    color: '#000000',
  },
  [userStatuses.active]: {
    label: 'active',
    color: '#67cb45',
  },
  [userStatuses.inactive]: {
    label: 'Inactive',
    color: '#cacaca',
  },
  [userStatuses.absent]: {
    label: 'Absent',
    color: '#000000',
  },
  [userStatuses.suspended]: {
    label: 'suspended',
    color: '#ff0000',
  },
  [userStatuses.deactivated]: {
    label: 'Deactivated',
    color: '#000000',
  },
}
