import { Typography } from 'antd'
import React from 'react'
import { useBreadcrumb } from '~/hooks/breadcrumbs'
import { Flex } from '~/components'

export const Dashboard = () => {
  useBreadcrumb(3, { label: 'Partners', path: '/partners/dashboard' })

  return (
    <Flex column alignCenter>
      <Typography.Title>Partners License Management</Typography.Title>
    </Flex>
  )
}
