import { Typography } from 'antd'
import React from 'react'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Flex } from './Flex'

const ActiveIndicator = styled.div`
  background-color: #1890ff;
  width: 3px;
  height: 15px;
  margin-right: 5px;
  opacity: ${props => (props.isActive ? 1 : 0)};
  transition: 250ms all ease-in-out;
`

export const Sidebar = ({ items }) => {
  const location = useLocation()

  return (
    <div style={{ width: 200, maxWidth: 200, marginRight: 15 }}>
      {items.map(({ label, isActive, path }, i) => {
        let isHighlighted = isActive
        if (items.find(el => el.isActive));
        else if (path === '/') {
          if (!items.filter((el, ind) => ind !== i).find(el => location.pathname.endsWith(el.path))) {
            isHighlighted = true
          }
        } else if (location.pathname.endsWith(path)) {
          isHighlighted = true
        }

        return (
          <Link to={`.${path}`} key={path}>
            <Flex noWrap alignCenter style={{ marginBottom: 10 }}>
              <ActiveIndicator data-testid={`active-indicator-${i}`} isActive={isHighlighted} />
              <Typography.Text>{label}</Typography.Text>
            </Flex>
          </Link>
        )
      })}
    </div>
  )
}
