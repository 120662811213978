import React from 'react'
import styled, { css } from 'styled-components'
import { Spinner } from '~/components/Spinner/Spinner'

const sharedStyles = css`
  padding: 8px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
`

const Wrapper = styled.div`
  ${sharedStyles}
`

const ClickableWrapper = styled.button`
  ${sharedStyles}
  border: none;
  background: none;
  cursor: pointer;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: background-color 0.2s;
  }

  &:hover::before {
    background-color: rgba(0, 0, 0, 0.25);
  }
`

const Img = styled.img`
  border-radius: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const Avatar = ({ imageUrl, isLoading, clickable = true, size = 128, onClick, ...other }) => {
  const content = isLoading ? <Spinner /> : <Img src={imageUrl} aria-label='User avatar' />
  const nonClickableWrapper = (
    <Wrapper size={size} {...other}>
      {content}
    </Wrapper>
  )

  if (isLoading) return nonClickableWrapper

  return clickable ? (
    <ClickableWrapper type='button' onClick={onClick} size={size} {...other}>
      {content}
    </ClickableWrapper>
  ) : (
    nonClickableWrapper
  )
}
