import { useParams } from 'react-router'
import React from 'react'
import { Typography } from 'antd'
import { Flex, Loader } from '~/components'
import { useBreadcrumb } from '~/hooks/breadcrumbs'
import { useUser } from '~/hooks/auth'
import { History } from './History'
import { Overview } from './Overview'
import { UserEvents } from './UserEvents'
import { useRequest } from '~/hooks/use-request'

export const UserDetails = () => {
  const { user: sessionUser, mutate: refetchMe } = useUser()
  const { userId } = useParams()

  useBreadcrumb(2, { label: 'Manage Users', path: `/users/list` })
  useBreadcrumb(3, { label: 'User Profile', path: `/users/user/${userId}` })

  const {
    data: historyData,
    error: historyError,
    isValidating: historyLoading,
    mutate: refetchHistory,
  } = useRequest(
    '/user/history/list',
    { userId: userId },
    {
      swrOptions: { revalidateOnFocus: false },
    }
  )

  const {
    data: user,
    isValidating,
    mutate: refetchUser,
    error,
  } = useRequest(
    '/user/details',
    { id: userId },
    {
      swrOptions: { revalidateOnFocus: false },
    }
  )

  const loading = !user && isValidating

  if (loading) return <Loader placeholder />

  if (error) return <Typography.Text type='danger'>{error.message}</Typography.Text>

  return (
    <Flex column>
      <Overview
        sessionUser={sessionUser}
        userId={userId}
        refetchHistory={refetchHistory}
        user={user}
        refetchUser={refetchUser}
        refetchMe={refetchMe}
      />

      <Flex column style={{ marginTop: 50 }}>
        <History
          historyItems={historyData?.historyItems}
          refetchHistory={refetchHistory}
          historyError={historyError}
          historyLoading={historyLoading}
        />

        <UserEvents userId={userId} />
      </Flex>
    </Flex>
  )
}
