import { HomeOutlined } from '@ant-design/icons'
import { useAuth0 } from '@auth0/auth0-react'
import { Breadcrumb } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import { Container } from '~/components/Container'
import { useBreadcrumbs } from '~/hooks/breadcrumbs'

export const Breadcrumbs = () => {
  const breadcrumbs = useBreadcrumbs()
  const { isAuthenticated: isLoggedIn } = useAuth0()

  if (!isLoggedIn || breadcrumbs.length === 1) return null
  const breadcrumbCount = breadcrumbs.length

  return (
    <Container small style={{ paddingBottom: 0 }}>
      <Breadcrumb>
        {breadcrumbs.map((b, index) => (
          <Breadcrumb.Item key={b.path}>
            {index === breadcrumbCount - 1 ? (
              b.label
            ) : (
              <Link to={b.path}>{b.path === '/' ? <HomeOutlined /> : b.label}</Link>
            )}
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
    </Container>
  )
}
