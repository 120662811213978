import React from 'react'
import { Comment, List, Empty, ConfigProvider } from 'antd'
import dayjs from 'dayjs'

import { config } from '~/config'
import { enhanceUser } from '~/hooks/auth'

export const HistoryNotes = ({ notes }) => (
  <ConfigProvider
    renderEmpty={() => <Empty description='' imageStyle={{ height: 40, paddingLeft: 40 }} />}>
    <List
      style={{ width: 320 }}
      className='comment-list'
      itemLayout='horizontal'
      dataSource={notes}
      renderItem={item => (
        <Comment
          data-testid={item.note}
          avatar={enhanceUser(item.adminUser).getAvatar().url}
          author={item.adminUser.username}
          content={<p>{item.note}</p>}
          datetime={dayjs(item.createdAt).format(config.DATE_FORMAT_FULL)}
        />
      )}
    />
  </ConfigProvider>
)
