import { request } from '~/http/request'

export const uploadFile = async file => {
  const { url, fields, location } = await request('/file/create-upload', {
    type: file.type,
    fileName: file.name,
    size: file.size,
  })

  const formData = new FormData()
  for (const key of Object.keys(fields)) {
    formData.append(key, fields[key])
  }

  formData.append('file', file)

  await fetch(url, { method: 'POST', body: formData })

  return { location }
}
